<template>
  <div class="index">
    <!-- <div class="index" style="filter: grayscale(1)"> -->
    <!-- 头部 -->
    <app-header @getHeight="getHeight" :phone="phone" />

    <div>
      <!-- <full-page :options="options" ref="fullpage"> -->
      <div class="section">
        <!-- 轮播图 -->
        <div
          class="index_carousel min_width"
          :style="{ 'margin-top': offsetHeight + 'px' }"
        >
          <el-carousel
            height="363px"
            :interval="5000"
            style="position: relative"
          >
            <el-carousel-item v-for="(item, index) in swiperList" :key="index">
              <img
                :src="item.img"
                class="index_swiper"
                @click="swiperUrl(item.url)"
              />
            </el-carousel-item>
          </el-carousel>
        </div>

        <!-- 搜索框
        <div
          class="topsearch"
          style="
            display: flex;
            align-items: center;
            position: absolute;
            top: 150px;
            z-index: 9;
            left: 150px;
          "
        >
          <div
            style="
              display: flex;
              align-items: center;
              background-color: #bde5fa;
            "
          >
            <el-dropdown
              style="
                width: 100px;
                text-align: center;
                border-right: 1px solid #ffffff;
              "
            >
              <span class="el-dropdown-link">
                全部<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>黄金糕</el-dropdown-item>
                <el-dropdown-item>狮子头</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <div class="topsearch">
              <el-input v-model="topkeyword" style="width: 220px"></el-input>
            </div>
          </div>

          <div
            style="
              width: 120px;
              background: #0d8eff;
              height: 40px;
              text-align: center;
              line-height: 40px;
            "
          >
            搜索
          </div>
        </div> -->

        <!-- 菜单 -->
        <section class="bgfff">
          <div class="max_width min_width">
            <div class="flex align_start jus_content width">
              <!-- 通知公告 -->
              <!-- <div class="flex column align_start width_38">
                  <div
                    class="
                      flex
                      align_center
                      jus_content
                      width
                      padb_20
                      border_bottom
                    "
                    @click="navigateUrl('/announcedetails')"
                  >
                    <div class="border_title">通知公告</div>
                    <div class="flex align_center cursor">
                      <div class="eze size_14 marr_8 font">更多</div>
                      <img
                        src="../../assets/image/more.png"
                        alt="more"
                        class="more_img"
                      />
                    </div>
                  </div> -->

              <!-- <div class="width"> -->
              <!-- <div
                      v-for="(item, index) in noticeList"
                      class="width"
                      @click="toAnnouncedetails(item.id)"
                    >
              <div v-if="index == 0" class="flex align_center cursor padt_20">
											<img :src="item.img" alt="pic" class="index_menu_pic" />
											<div class="marl_12 index_menu_title">
												<div class="size_17 weight ttt text_line1 font" :title="item.title">
													{{item.title}}
												</div>
												<div class="c61 size_15 mart_20 time_font">{{item.create_time}}</div>
											</div>
										</div>

              <div class="cursor width padtb_12">
                        <div class="flex align_center jus_content font">
                          <div class="flex align_center" style="width: 83%">
                            <div class="index_radius marr_12"></div>
                            <div
                              class="size_15 font text_line1 index_menu_font"
                              :title="item.title"
                            >
                              {{ item.title }}
                            </div>
                          </div>

                          <div class="size_14">{{ item.create_time }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> -->

              <!-- 热点动态 -->
              <!-- <div class="flex column align_start width_38">
                  <div
                    class="
                      flex
                      align_center
                      jus_content
                      width
                      padb_20
                      border_bottom
                    "
                    @click="navigateUrl('/hotdetails')"
                  >
                    <div class="border_title">新闻动态</div>
                    <div class="flex align_center cursor">
                      <div class="eze size_14 marr_8 font">更多</div>
                      <img
                        src="../../assets/image/more.png"
                        alt="more"
                        class="more_img"
                      />
                    </div>
                  </div>

                  <div class="width">
                    <div
                      v-for="(item, index) in hotList"
                      class="width"
                      @click="toHotdetails(item.id)"
                    > -->
              <!-- <div v-if="index == 0" class="flex align_center cursor padt_20">
											<img :src="item.img" alt="pic" class="index_menu_pic" />
											<div class="marl_12 index_menu_title">
												<div class="size_17 weight ttt text_line1 font" :title="item.title">
													{{item.title}}
												</div>
												<div class="c61 size_15 mart_20 time_font">{{item.create_time}}</div>
											</div>
										</div> -->

              <!-- <div class="cursor width padtb_12">
                        <div class="flex align_center jus_content font">
                          <div class="flex align_center" style="width: 83%">
                            <div class="index_radius marr_12"></div>
                            <div
                              class="size_15 text_line1 index_menu_font"
                              :title="item.title"
                            >
                              {{ item.title }}
                            </div>
                          </div>

                          <div class="size_14">{{ item.create_time }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> -->

              <!-- 跳转图片 -->
              <!-- <div
                  class="width_20 flex column jus_content height"
                  style="height: 295px"
                >
                  <img
                    src="../../assets/image/10.jpg"
                    class="index_service_pic cursor"
                    @click="toUrl(3)"
                  />
                  <img
                    src="../../assets/image/8.jpg"
                    class="index_service_pic cursor"
                    @click="toUrl(1)"
                  />
                  <img
                    src="../../assets/image/9.png"
                    class="index_service_pic cursor"
                    @click="toUrl(2)"
                  />
                </div> -->
            </div>
            <!-- class="min_height" -->
            <div class="bgfff index_pad_crad">
              <div class="">
                <div class="flex align_center width">
                  <div @click="goweb(1)" class="imgbox">
                    <img
                      class="topimg"
                      src="../../assets/image/index1.png"
                      alt=""
                    />
                  </div>
                  <div
                    @click="goweb(2)"
                    class="imgbox"
                    style="margin-left: 50px"
                  >
                    <img
                      class="topimg"
                      src="../../assets/image/index2.png"
                      alt=""
                    />
                  </div>
                  <div
                    @click="goweb(3)"
                    class="imgbox"
                    style="margin-left: 50px"
                  >
                    <img
                      class="topimg"
                      src="../../assets/image/index3.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="bgfff">
            <div class="">
              <div class="flex align_center width">
                <div
                  class="flex align_start"
                  style="
                    margin: 0px auto;
                    width: 1480px;
                    justify-content: space-between;
                  "
                >
                  <el-carousel height="490px" :interval="5000" class="carousel">
                    <el-carousel-item
                      v-for="(item, index) in swipersmallList"
                      :key="index"
                      style="width: 700px; height: 490px"
                    >
                      <img
                        :src="item.img"
                        class="index_swiper"
                        @click="swiperUrl(item.url)"
                        style="width: 700px; height: 490px"
                      />
                    </el-carousel-item>
                  </el-carousel>

                  <div
                    class="flex column align_start"
                    style="
                      width: 680px;
                      height: 490px;
                      margin-left: 50px;
                      overflow: hidden;
                    "
                  >
                    <div
                      class="flex align_center width padb_20 border_bottom statisticsbigbox"
                      style=""
                    >
                      <div style="display: flex">
                        <div
                          class="size_20 weight marl_12"
                          @click="gostatus(1)"
                        >
                          最新动态
                          <div
                            v-show="dynamicState === 1"
                            style="
                              width: 70px;
                              height: 4px;
                              background: #ff3333;
                              border-radius: 2px;
                              position: relative;
                              top: 20px;
                            "
                          ></div>
                        </div>
                        <div
                          class="size_20 weight marl_20"
                          style=""
                          @click="gostatus(2)"
                        >
                          通知公告
                          <div
                            v-show="dynamicState === 2"
                            style="
                              width: 70px;
                              height: 4px;
                              background: #ff3333;
                              border-radius: 2px;
                              position: relative;
                              top: 20px;
                            "
                          ></div>
                        </div>
                      </div>
                      <div
                        class="flex align_center cursor"
                        v-show="dynamicState === 1"
                        @click="navigateUrl('/hotdetails')"
                      >
                        <div class="eze size_14 marr_8 font">更多</div>
                        <img
                          src="../../assets/image/more.png"
                          alt="more"
                          class="more_img"
                        />
                      </div>

                      <div
                        class="flex align_center cursor"
                        v-show="dynamicState === 2"
                        @click="navigateUrl('/announcedetails')"
                      >
                        <div class="eze size_14 marr_8 font">更多</div>
                        <img
                          src="../../assets/image/more.png"
                          alt="more"
                          class="more_img"
                        />
                      </div>
                    </div>

                    <div class="width" v-if="dynamicState === 1">
                      <div
                        v-for="(item, index) in hotList"
                        :key="index"
                        @click="toHotdetails(item.id)"
                      >
                        <!-- <div v-if="index == 0" class="flex align_center cursor">
                          <div
                            class="size_17 weight ttt text_line1 font"
                            style="flex: 1"
                            :title="item.title"
                          >
                            {{ item.title }}
                          </div>
                          <div class="c61 size_15 mart_20 time_font">
                            {{ item.create_time }}
                          </div>
                        </div> -->

                        <div
                          class="cursor width"
                          style="padding: 18px 10px 0px 10px"
                        >
                          <div class="flex align_center jus_content font">
                            <div class="flex align_center" style="width: 83%">
                              <div class="index_radius marr_12"></div>
                              <div
                                class="size_16 text_line1 index_menu_font"
                                :title="item.title"
                              >
                                {{ item.title }}
                              </div>
                            </div>

                            <div class="size_16">{{ item.create_time }}</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- <div class="width"> -->

                    <div
                      v-else
                      v-for="(item, index) in noticeList"
                      :key="index"
                      class="width"
                      @click="toAnnouncedetails(item.id)"
                    >
                      <!-- <div
                        v-if="index == 0"
                        class="flex align_center cursor padt_20"
                      >
                        <img :src="item.img" alt="pic" class="index_menu_pic" />
                        <div class="marl_12 index_menu_title">
                          <div
                            class="size_17 weight ttt text_line1 font"
                            :title="item.title"
                          >
                            {{ item.title }}
                          </div>
                          <div class="c61 size_15 mart_20 time_font">
                            {{ item.create_time }}
                          </div>
                        </div>
                      </div> -->

                      <div
                        class="cursor width"
                        style="padding: 18px 10px 0px 10px"
                      >
                        <div class="flex align_center jus_content font">
                          <div class="flex align_center" style="width: 83%">
                            <div class="index_radius marr_12"></div>
                            <div
                              class="size_16 text_line1 index_menu_font"
                              :title="item.title"
                            >
                              {{ item.title }}
                            </div>
                          </div>

                          <div class="size_16">{{ item.create_time }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="bgfff index_pad_crad">
            <div class="padb_20">
              <div class="flex align_center min_width max_width">
                <div class="flex align_start width statisticsbigbox">
                  <div
                    class="statisticsbox"
                    style=""
                    v-for="(item, index) in policy"
                    :key="index"
                  >
                    <div class="statistics">
                      <div
                        class="statistics_num"
                        :style="{ color: item.color }"
                      >
                        {{ item.num }}
                      </div>
                      {{ item.type }}
                    </div>
                    <div
                      class="statistics_title"
                      :style="{ color: item.color }"
                    >
                      {{ item.title }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->

          <!-- <div
            class="flex align_start"
            style="margin: 10px 0px; justify-content: space-around"
          >
            <el-carousel height="363px" :interval="5000" style="width: 600px">
              <el-carousel-item
                v-for="(item, index) in swiperList"
                :key="index"
              >
                <img
                  :src="item.img"
                  class="index_swiper"
                  @click="swiperUrl(item.url)"
                />
              </el-carousel-item>
            </el-carousel>

            <div class="flex column align_start width_38">
              <div
                class="
                  flex
                  align_center
                  jus_content
                  width
                  padb_20
                  border_bottom
                "
                @click="navigateUrl('/hotdetails')"
              >
                <div class="border_title">新闻动态</div>
                <div class="flex align_center cursor">
                  <div class="eze size_14 marr_8 font">更多</div>
                  <img
                    src="../../assets/image/more.png"
                    alt="more"
                    class="more_img"
                  />
                </div>
              </div>

              <div class="width">
                <div
                  v-for="(item, index) in hotList"
                  class="width"
                  @click="toHotdetails(item.id)"
                >
                  <div
                    v-if="index == 0"
                    class="flex align_center cursor padt_20"
                  >
                    <div class="marl_12 index_menu_title">
                      <div
                        class="size_17 weight ttt text_line1 font"
                        :title="item.title"
                      >
                        {{ item.title }}
                      </div>
                      <div class="c61 size_15 mart_20 time_font">
                        {{ item.create_time }}
                      </div>
                    </div>
                  </div>

                  <div class="cursor width padtb_12">
                    <div class="flex align_center jus_content font">
                      <div class="flex align_center" style="width: 83%">
                        <div class="index_radius marr_12"></div>
                        <div
                          class="size_15 text_line1 index_menu_font"
                          :title="item.title"
                        >
                          {{ item.title }}
                        </div>
                      </div>

                      <div class="size_14">{{ item.create_time }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        </section>
      </div>

      <div class="bgfff index_pad_crad">
        <div class="">
          <div class="flex align_center min_width max_width">
            <div class="flex align_start width statisticsbigbox">
              <div>
                <div class="statistics">
                  <div class="statistics_num" style="color: #2d5dfe">
                    {{ indexlist.policy_num }}
                  </div>
                  条
                </div>
                <div class="statistics_title" style="color: #2d5dfe">
                  惠企政策
                </div>
              </div>
              <div>
                <div class="statistics">
                  <div class="statistics_num" style="color: #ff6b21">
                    {{ indexlist.registrations }}
                  </div>
                  家
                </div>
                <div class="statistics_title" style="color: #ff6b21">
                  服务机构数量
                </div>
              </div>
              <div>
                <div class="statistics">
                  <div class="statistics_num" style="color: #5b2ef6">
                    {{ indexlist.release_volume }}
                  </div>
                  条
                </div>
                <div class="statistics_title" style="color: #5b2ef6">
                  服务产品发布量
                </div>
              </div>
              <div>
                <div class="statistics">
                  <div class="statistics_num" style="color: #ff2b2b">
                    {{ indexlist.organization_number }}
                  </div>
                  次
                </div>
                <div class="statistics_title" style="color: #ff2b2b">
                  本月开展活动次数
                </div>
              </div>
              <div>
                <div class="statistics">
                  <div class="statistics_num" style="color: #ffa00e">
                    {{ indexlist.enterprise_registration }}
                  </div>
                  户
                </div>
                <div class="statistics_title" style="color: #ffa00e">
                  入库企业数
                </div>
              </div>
              <div>
                <div class="statistics">
                  <div class="statistics_num" style="color: #39e5cb">
                    {{ indexlist.visits }}
                  </div>
                  次
                </div>
                <div class="statistics_title" style="color: #39e5cb">
                  访问次数
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <section>
        <el-carousel height="363px" :interval="5000">
          <el-carousel-item v-for="(item, index) in swiperList" :key="index">
            <img
              :src="item.img"
              class="index_swiper"
              @click="swiperUrl(item.url)"
            />
          </el-carousel-item>
        </el-carousel>
      </section> -->

      <!-- 政策法规 -->
      <section class="section bgfff">
        <div class="max_width min_width">
          <!-- <div
            class="flex column jus_content align_center marb_30"
            @click="titleTap(1)"
          >
            <div class="ttt size_22 weight">政策法规</div>
            <div class="index_line"></div>
          </div> -->

          <div class="flex align_start">
            <div class="" style="width: calc(69% - 40px)">
              <!-- <div class="width index_search">
                <el-input placeholder="请输入关键词" v-model="keyword">
                  <i
                    slot="suffix"
                    class="el-icon-search"
                    @click="toLaws('search')"
                  ></i>
                </el-input>
              </div> -->

              <div class="bgfff">
                <div class="padb_20 border_bottom" style="margin: 0px 16px">
                  <div class="flex align_center width jus_content">
                    <div class="flex align_center">
                      <div
                        style="
                          width: 4px;
                          height: 19px;
                          background: #fb1010;
                          border-radius: 2px;
                        "
                      ></div>
                      <div
                        class="size_20 weight marl_12"
                        v-if="policyClass.length != 0"
                        @click="lookzhengce"
                      >
                        政策法规
                      </div>

                      <!-- <div
                        class="size_18 weight marl_12"
                        v-if="policyClass.length != 0"
                        @click="lookfagui"
                      >
                        政策解读
                      </div> -->
                    </div>

                    <div
                      class="flex align_center cursor"
                      @click="toLaws('first')"
                      v-if="zhengce === true"
                    >
                      <div class="eze size_14 marr_8 font">更多</div>
                      <img
                        src="../../assets/image/more.png"
                        alt="more"
                        class="more_img"
                      />
                    </div>

                    <!-- <div
                      class="flex align_center cursor"
                      @click="toLaws('third')"
                      v-if="fagui === true"
                    >
                      <div class="eze size_14 marr_8 font">更多</div>
                      <img
                        src="../../assets/image/more.png"
                        alt="more"
                        class="more_img"
                      />
                    </div> -->
                  </div>
                </div>

                <div
                  class="mart_30"
                  style="height: 460px; overflow: hidden"
                  v-if="zhengce === true"
                >
                  <div
                    v-for="item in policyList.laws"
                    :key="item.id"
                    class="index_crad_msg align_center jus_content font cursor"
                    @click="toLawsDetails(item.id)"
                  >
                    <!-- flex -->
                    <div class="flex align_center">
                      <div class="index_radius marr_12"></div>
                      <div class="text_line1 width size_16" :title="item.title">
                        {{ item.title }}
                      </div>
                      <!-- <div class="smalltext">{{ item.create_time }}</div> -->
                    </div>

                    <div class="flex width size_16">
                      <div style="padding: 0px 20px" class="smalltext">
                        {{ item.source }}
                      </div>
                      <div class="smalltext">{{ item.create_time }}</div>
                    </div>
                  </div>
                </div>

                <div class="mart_30" v-if="fagui === true">
                  <div
                    v-for="item in policyList.declare"
                    :key="item.id"
                    class="index_crad_msg align_center jus_content font cursor"
                    @click="toLawsDetails(item.id)"
                  >
                    <div class="flex align_center width_80">
                      <div class="index_radius marr_12"></div>
                      <div class="text_line1 width size_16" :title="item.title">
                        {{ item.title }}
                      </div>
                    </div>
                    <div class="flex width jus_content size_16">
                      <div style="padding: 0px 20px">{{ item.source }}</div>
                      <div>{{ item.create_time }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="bgfff"
              style="width: calc(30% - 0px); margin-left: 50px"
            >
              <div class="" style="margin: 0px 16px; padding-bottom: 10px">
                <div class="flex align_center width jus_content">
                  <div class="flex align_center">
                    <div
                      style="
                        width: 4px;
                        height: 19px;
                        background: #fb1010;
                        border-radius: 2px;
                      "
                    ></div>
                    <div class="size_20 weight marl_12">专题专栏</div>
                  </div>
                </div>
              </div>

              <div class="flex" style="flex-wrap: wrap">
                <div style="padding: 10px 5px">
                  <div class="specialbox" @click="goweb(4)">
                    <img
                      src="../../assets/image/special1.png"
                      style="margin: 30px 75px 20px"
                      alt=""
                    />
                    <div class="special_title titlehave">
                      工业和信息化部
                      <span style="display: block; color: #808080"
                        >中小企业服务跟踪平台</span
                      >
                    </div>
                    <div class="look_special">
                      <span class="look_title">查看专区</span>
                    </div>
                  </div>
                </div>

                <div style="padding: 10px 5px">
                  <div class="specialbox" @click="goweb(6)">
                    <img
                      src="../../assets/image/special2.png"
                      style="margin: 30px 75px 20px"
                      alt=""
                    />
                    <div class="special_title titlehave">
                      青海省
                      <span style="display: block; color: #808080"
                        >中小企业数据库</span
                      >
                    </div>

                    <div class="look_special">
                      <span class="look_title">查看专区</span>
                    </div>
                  </div>
                </div>

                <div style="padding: 10px 5px">
                  <div class="specialbox" @click="goweb(7)">
                    <img
                      src="../../assets/image/special3.png"
                      style="margin: 30px 75px 20px"
                      alt=""
                    />
                    <div class="special_title titlehave">
                      青海省
                      <span style="display: block; color: #808080"
                        >中小企业服务跟踪平台</span
                      >
                    </div>

                    <div class="look_special">
                      <span class="look_title">查看专区</span>
                    </div>
                  </div>
                </div>

                <div style="padding: 10px 5px" @click="gocommissioner()">
                  <div class="specialbox">
                    <img
                      src="../../assets/image/special4.png"
                      style="margin: 30px 75px 20px"
                      alt=""
                    />
                    <div class="special_title titlehave">
                      专精特新
                      <span style="display: block; color: #808080"
                        >中小企业服务专员</span
                      >
                    </div>

                    <div class="look_special">
                      <span class="look_title">查看专区</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div>
              <div
                class="width_calc30 bgfff"
                style="
                  padding: 30px 12px 0px 30px;
                  width: 680px;
                  overflow: hidden;
                  height: 400px;
                "
              >
                <div
                  class="
                    flex
                    align_center
                    width
                    jus_content
                    border_bottom
                    padb_20
                  "
                >
                  <div class="flex align_center">
                    <div class="size_18 weight marl_12">融资服务</div>
                  </div>
                  <div class="flex align_center cursor" @click="toFin">
                    <div class="eze size_14 marr_8 font">更多</div>
                    <img
                      src="../../assets/image/more.png"
                      alt="more"
                      class="more_img"
                    />
                  </div>
                </div>

                <div class="index_financiallist">
                  <el-table
                    :data="financialList"
                    height="510"
                    ref="financial"
                    class="index_appeal_table"
                    stripe
                    @cell-mouse-enter="cellFMouseEnter"
                    @cell-mouse-leave="financialMove"
                    :show-header="false"
                    :highlight-current-row="true"
                  >
                    <el-table-column
                      prop="name"
                      label="企业名称"
                      align="left"
                      height="558px;"
                    >
                      <template slot-scope="scope">
                        <span>{{ scope.row.name }}</span>
                        <span style="color: #ffa71d">受理</span>
                        <span>{{ scope.row.company_name }}</span>
                        <span>{{ scope.row.accept_quota }}万融资需求</span>
                      </template>
                    </el-table-column>

                    <el-table-column
                      prop="accept_time"
                      label="时间"
                      align="right"
                      min-width="28%"
                    >
                    </el-table-column>
                  </el-table>
                </div>
              </div>

              <div
                class="width_calc30 bgfff"
                style="
                  padding: 20px 12px 0px 30px;
                  width: 680px;
                  overflow: hidden;
                  height: 180px;
                "
              >
                <div class="flex align_center width jus_content border_bottom">
                  <div class="flex align_center">
                    <div class="size_18 weight" style="padding-bottom: 5px">
                      融资产品
                    </div>
                  </div>
                </div>
                <div
                  style="
                    display: flex;
                    justify-content: space-between;
                    padding: 10px 0px;
                  "
                >
                  <img src="../../assets/image/1665626948796.png" class="" />
                  <img src="../../assets/image/1665626948796.png" class="" />
                  <img src="../../assets/image/1665626948796.png" class="" />
                </div>
              </div>
            </div> -->

            <!-- <div class="width_calc">
              <div class="bgfff index_pad_crad">
                <div class="padb_20 border_bottom">
                  <div
                    class="flex align_center width jus_content"
                    style="padding: 0 16px"
                  >
                    <div class="flex align_center">
                      <img
                        src="../../assets/image/2.png"
                        class="index_policy_pic"
                      />
                      <div
                        class="size_18 weight marl_12"
                        v-if="policyClass.length != 0"
                      >
                        {{ policyClass[1].name }}
                      </div>
                    </div>

                    <div
                      class="flex align_center cursor"
                      @click="toLaws('second')"
                    >
                      <div class="eze size_14 marr_8 font">更多</div>
                      <img
                        src="../../assets/image/more.png"
                        alt="more"
                        class="more_img"
                      />
                    </div>
                  </div>
                </div>

                <div class="mart_30" style="height: 260px">
                  <div
                    v-for="item in policyList.unscramble"
                    :key="item.id"
                    class="
                      index_crad_msg
                      flex
                      align_center
                      jus_content
                      font
                      cursor
                    "
                    @click="toLawsDetails(item.id)"
                  >
                    <div class="flex align_center width_80">
                      <div class="index_radius marr_12"></div>
                      <div class="text_line1 width size_15" :title="item.title">
                        {{ item.title }}
                      </div>
                    </div>
                    <div>{{ item.create_time }}</div>
                  </div>
                </div>
              </div>

              <div class="bgfff index_pad_crad mart_30">
                <div class="padb_20 border_bottom">
                  <div
                    class="flex align_center width jus_content"
                    style="padding: 0 16px"
                  >
                    <div class="flex align_center">
                      <img
                        src="../../assets/image/2.png"
                        class="index_policy_pic"
                      />
                      <div
                        class="size_18 weight marl_12"
                        v-if="policyClass.length != 0"
                      >
                        {{ policyClass[2].name }}
                      </div>
                    </div>

                    <div
                      class="flex align_center cursor"
                      @click="toLaws('third')"
                    >
                      <div class="eze size_14 marr_8 font">更多</div>
                      <img
                        src="../../assets/image/more.png"
                        alt="more"
                        class="more_img"
                      />
                    </div>
                  </div>
                </div>

                <div class="mart_30" style="height: 140px">
                  <div
                    v-for="item in policyList.declare"
                    :key="item.id"
                    class="
                      index_crad_msg
                      flex
                      align_center
                      jus_content
                      font
                      cursor
                    "
                    @click="toLawsDetails(item.id)"
                  >
                    <div class="flex align_center width_80">
                      <div class="index_radius marr_12"></div>
                      <div class="text_line1 width size_15" :title="item.title">
                        {{ item.title }}
                      </div>
                    </div>
                    <div>{{ item.create_time }}</div>
                  </div>
                </div>
              </div> 
            </div>-->
          </div>
        </div>
      </section>
      <div class="bgfff index_pad_crad" @click="goweb(5, banner_img.url)">
        <div style="width: 1480px; height: 310px; margin: 0 auto">
          <!-- <img class="topimg" src="../../assets/image/indeximage.jpg" alt="" /> -->
          <img class="topimg" :src="banner_img.img" alt="" />
        </div>
      </div>
      <div class="max_width min_width" style="padding-bottom: 20px">
        <div class="size_18 weight flex" style="align-items: center">
          <div
            style="
              width: 4px;
              height: 19px;
              background: #fb1010;
              border-radius: 2px;
            "
          ></div>
          <div class="size_20 weight marl_12">青企链</div>
        </div>
      </div>
      <div class="bgfff" style="padding-bottom: 30px">
        <div class="flex align_center min_width max_width">
          <div style="width: 710px; height: 200px" @click="gomarket()">
            <img class="topimg" src="../../assets/image/market.png" alt="" />
          </div>
          <div
            style="width: 710px; height: 200px; margin-left: 60px"
            @click="goneed()"
          >
            <img class="topimg" src="../../assets/image/need.png" alt="" />
          </div>
        </div>
      </div>

      <div class="" style="background-color: #fafafa; padding: 50px 60px">
        <div class="">
          <div class="flex align_center min_width max_width">
            <div
              class="flex align_start width"
              style="background-color: #ffffff"
            >
              <div style="padding: 50px">
                <div
                  style="
                    width: 19px;
                    height: 4px;
                    background: #fb1010;
                    border-radius: 2px;
                  "
                ></div>

                <!-- <div
                  style="
                    width: 23px;
                    height: 120px;
                    font-size: 24px;
                    font-weight: 500;
                    text-align: left;
                    color: rgb(46, 47, 48);
                    line-height: 30px;
                    padding-right: 40px;
                    margin-top: 10px;
                  "
                > -->
                <div
                  class="size_20 weight"
                  style="width: 23px; margin-top: 10px"
                >
                  服务产品
                </div>
              </div>
              <div
                style="
                  display: flex;
                  flex: 1;
                  border-left: 1px solid #e6e6e6;
                  top: 10px;
                  position: relative;
                "
              >
                <div
                  style="padding: 50px 0px 50px 70px"
                  v-for="(item, index) in options"
                  @click="goserveProduct(item)"
                  :key="item.id"
                >
                  <div v-if="index < 8">
                    <img
                      class="topimg"
                      :src="item.img"
                      style="width: 76px; height: 76px"
                      alt=""
                    />
                    <div style="text-align: center" class="size_16 servehove">
                      {{ item.name }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="" style="height: 700px; background-color: #fafafa">
        <div class="padb_20">
          <div class="flex align_center min_width max_width">
            <!-- <div>
                
              </div> -->
            <div
              style="
                display: flex;
                align-items: center;
                background-color: #ffffff;
                padding: 30px 0px 30px 20px;
              "
            >
              <div>
                <div class="flex" style="align-items: center">
                  <div
                    style="
                      width: 4px;
                      height: 19px;
                      background: #fb1010;
                      border-radius: 2px;
                    "
                  ></div>
                  <div class="size_20 weight marl_12">
                    中小企业服务体系构成图
                  </div>
                </div>
                <div
                  class=""
                  style="
                    position: relative;
                    top: 320px;
                    right: -150px;
                    z-index: -999;
                  "
                  @click="gospecial"
                >
                  查看更多
                </div>
                <div id="myCharts2" ref="Charts2" style="width: 700px"></div>

                <!-- <div class="size_14" style="text-align: center; position: relative; top: -40px">
                  数据来源： 国家企业信用信息公示系统
                  <span class="marl_12">数据截止时间：2022年10月13日</span>
                </div> -->
              </div>
            </div>

            <div
              style="
                background-color: rgb(255, 255, 255);
                padding: 30px 0px 30px 20px;
                margin-left: 30px;
              "
            >
              <div>
                <div>
                  <div class="size_18 weight flex" style="align-items: center">
                    <div class="flex" style="align-items: center">
                      <div
                        style="
                          width: 4px;
                          height: 19px;
                          background: #fb1010;
                          border-radius: 2px;
                        "
                      ></div>
                      <div class="size_20 weight marl_12">
                        “专精特新”中小企业分布图
                      </div>
                    </div>
                    <div
                      class="size_20 weight"
                      style="
                        position: relative;
                        top: 170px;
                        right: 110px;
                        font-size: 18px;
                        display: inline-block;
                        cursor: pointer;
                        z-index: 99;
                      "
                      @click="gospecial(1)"
                    >
                      查看更多
                    </div>
                  </div>

                  <div id="myCharts" ref="myCharts" style="width: 700px"></div>

                  <!-- <div class="size_14" style="text-align: center; position: relative; top: -40px">
                  数据来源：青海省工业和信息化厅
                  <span class="marl_12">数据截止时间：2022年4月</span>
                </div> -->
                </div>
                <div style="margin-top: 20px">
                  <div class="flex jus_content align_center">
                    <div class="flex align_center">
                      <div
                        style="
                          width: 4px;
                          height: 19px;
                          background: #fb1010;
                          border-radius: 2px;
                        "
                      ></div>
                      <div class="size_20 weight marl_12">
                        创新型中小企业分布图
                      </div>
                    </div>
                    <div
                      class="size_20 weight flex align_center"
                      style="margin-right: 30px"
                      @click="gospecial(2)"
                    >
                      <div class="eze size_14 marr_8 font">更多</div>
                      <img
                        src="../../assets/image/more.png"
                        alt="more"
                        class="more_img"
                      />
                    </div>
                  </div>
                  <div
                    id="myCharts3"
                    ref="myCharts3"
                    style="width: 700px"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="" style="height: 1200px; background-color: #fafafa;padding: 50px 60px;" >
        <div class="padb_20">
          <div class="flex align_center min_width max_width">
            <div id="container"></div>
          </div>
        </div>
      </div> -->
      <!-- 青海省工业小微企业融资服务 -->
      <!-- <section class="index_enterprise padtb_30 section">
        <div class="max_width min_width">
          <div
            class="flex column jus_content align_center marb_30"
            @click="titleTap(2)"
          >
            <div class="ttt size_22 weight">工业小微企业融资服务</div>
            <div class="index_line"></div>
          </div>

          <div class="flex align_start jus_content">
            <div class="width_calc70 bgfff index_enterprise_pad">
              <div class="flex align_center width jus_content">
                <div class="flex align_center">
                  <img
                    src="../../assets/image/15.png"
                    class="index_policy_pic"
                    style="height: 20px"
                  />
                  <div class="size_18 weight marl_12">金融产品</div>
                </div>

                <div class="flex align_center cursor" @click="toFin">
                  <div class="eze size_14 marr_8 font">更多</div>
                  <img
                    src="../../assets/image/more.png"
                    alt="more"
                    class="more_img"
                  />
                </div>
              </div>

              <div class="width" style="padding-bottom: 3px">
                <div
                  v-for="item in productList"
                  :key="item.id"
                  class="index_enterprise_bor flex align_start"
                  @click="toProductsdetail(item.id)"
                >
                  <div class="index_enterprise_picBox cursor">
                    <el-image
                      fit="fill"
                      class="index_enterprise_pic"
                      :src="item.gallery"
                    >
                      <div slot="error" class="image-slot">
                        <i
                          class="el-icon-picture-outline"
                          style="font-size: 30px"
                        ></i>
                      </div>
                    </el-image>
                  </div>

                  <div
                    class="flex column marl_20"
                    style="width: calc(100% - 240px)"
                  >
                    <div
                      class="size_16 index_enterprise_title font cursor"
                      :title="item.name"
                    >
                      {{ item.name }}
                    </div>
                    <div
                      class="flex jus_content align_end"
                      style="margin-top: 10px"
                    >
                      <div style="width: calc(100% - 86px)">
                        <div class="flex align_center no_wrap">
                          <div class="fnf size_13 marr_30 text_nowrap">
                            融资额度：<span class="ffa"
                              >{{ item.min_quota }}~{{ item.max_quota }}万</span
                            >
                          </div>
                          <div class="fnf size_13 marr_30 text_nowrap">
                            利率：<span class="ffa"
                              >{{ item.min_rate }}%~{{ item.max_rate }}%</span
                            >
                          </div>
                          <div
                            class="fnf size_13 text_nowrap text_line1"
                            style="width: 135px"
                          >
                            适用地区：{{ item.area_name }}
                          </div>
                        </div>

                        <div class="flex align_center mart_8 no_wrap">
                          <div class="fnf size_15 marr_30 text_nowrap">
                            抵款期限：{{ item.min_credit }}~{{
                              item.max_credit
                            }}月
                          </div>
                          <div class="fnf size_15 text_nowrap">
                            担保：{{ item.cate_name }}
                          </div>
                        </div>
                      </div>
                      <div class="index_apply">立即申请</div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->

      <!-- <div class="width_calc30 bgfff" style="padding: 30px 12px 0 30px">
              <div class="flex align_center width jus_content">
                <div class="flex align_center">
                  <img
                    src="../../assets/image/3.png"
                    class="index_policy_pic"
                  />
                  <div class="size_18 weight marl_12">最新融资动态</div>
                </div>
              </div>

              <div class="index_financiallist">
                <el-table
                  :data="financialList"
                  height="510"
                  ref="financial"
                  class="index_appeal_table"
                  stripe
                  @cell-mouse-enter="cellFMouseEnter"
                  @cell-mouse-leave="financialMove"
                  :show-header="false"
                  :highlight-current-row="true"
                >
                  <el-table-column
                    prop="name"
                    label="企业名称"
                    align="left"
                    min-width="72%"
                  >
                    <template slot-scope="scope">
                      <span>{{ scope.row.name }}</span>
                      <span style="color: #ffa71d">受理</span>
                      <span>{{ scope.row.company_name }}</span>
                      <span>{{ scope.row.accept_quota }}万融资需求</span>
                    </template>
                  </el-table-column>

                  <el-table-column
                    prop="accept_time"
                    label="时间"
                    align="right"
                    min-width="28%"
                  >
                  </el-table-column>
                </el-table>
              </div>
            </div> -->
      <!-- </div> -->

      <!-- 统计 -->
      <!-- <div
            class="width flex align_center jus_around bgfff padtb_12"
            style="margin-top: 30px"
          >
            <div class="width flex column align_center cursor">
              <div class="size_16 ttt index_statistical">
                <span>{{ finCount3 }}</span
                >家
              </div>
              <div class="flex align_center mart_3">
                <img
                  src="../../assets/image/icon/6.png"
                  class="index_statistical_icon"
                />
                <div class="size_14 ttt">入驻机构</div>
              </div>
            </div>

            <div class="width flex column align_center cursor">
              <div class="size_16 ttt index_statistical">
                <span>{{ finCount2 }}</span
                >家
              </div>
              <div class="flex align_center mart_3">
                <img
                  src="../../assets/image/icon/7.png"
                  class="index_statistical_icon"
                />
                <div class="size_14 ttt">入驻企业</div>
              </div>
            </div>

            <div class="width flex column align_center cursor">
              <div class="size_16 ttt index_statistical">
                <span>{{ finCount7 }}</span
                >家
              </div>
              <div class="flex align_center mart_3">
                <img
                  src="../../assets/image/icon/8.png"
                  class="index_statistical_icon"
                />
                <div class="size_14 ttt">授信金额</div>
              </div>
            </div>

            <div class="width flex column align_center cursor">
              <div class="size_16 ttt index_statistical">
                <span>{{ finCount6 }}</span
                >万元
              </div>
              <div class="flex align_center mart_3">
                <img
                  src="../../assets/image/icon/9.png"
                  class="index_statistical_icon"
                />
                <div class="size_14 ttt">申请总金额</div>
              </div>
            </div>

            <div class="width flex column align_center cursor">
              <div class="size_16 ttt index_statistical">
                <span>{{ finCount4 }}</span
                >万元
              </div>
              <div class="flex align_center mart_3">
                <img
                  src="../../assets/image/icon/10.png"
                  class="index_statistical_icon"
                />
                <div class="size_14 ttt">需求发布数</div>
              </div>
            </div>

            <div class="width flex column align_center cursor">
              <div class="size_16 ttt index_statistical">
                <span>{{ finCount1 }}</span
                >次
              </div>
              <div class="flex align_center mart_3">
                <img
                  src="../../assets/image/icon/11.png"
                  class="index_statistical_icon"
                />
                <div class="size_14 ttt">产品总数量</div>
              </div>
            </div>
          </div>
        </div>
      </section> -->

      <!-- 青海省中小企业诉求意见及保障支付受理 -->
      <!-- <section class="index_appeal padtb_30 section">
        <div class="max_width min_width">
          <div class="flex align_start jus_content">
            <div
              class="width_calc bgfff"
              style="border-radius: 6px; padding-bottom: 20px"
            >
              <div class="ttt size_22 weight" style="padding: 20px">
                企诉保障
              </div>
              <div class="flex align_start jus_content">
                <div
                  class="flex align_center index_appeal_box bgcce"
                  @click="toPortal(1)"
                >
                  <div class="marr_70">
                    <div class="d8f size_16 weight">企业诉求</div>
                    <div class="d8f size_16 weight mart_10">意见登记</div>
                  </div>

                  <img
                    src="../../assets/image/6.png"
                    class="index_appeal_pic"
                  />
                </div>

                <div
                  class="flex align_center index_appeal_box bgffe"
                  @click="toPortal(2)"
                >
                  <div class="marr_70">
                    <div class="ffa size_16 weight">保障款项</div>
                    <div class="ffa size_16 weight mart_10">支付登记</div>
                  </div>

                  <img
                    src="../../assets/image/7.png"
                    class="index_appeal_pic"
                  />
                </div>
              </div>

              <div
                class="flex align_center width jus_content"
                style="padding: 16px 20px"
              >
                <div class="flex align_center">
                  <div class="size_18 weight marl_12">诉求反馈</div>
                </div>

                <div class="flex align_center cursor" @click="toPortal(3)">
                  <div class="eze size_14 marr_8 font">更多</div>
                  <img
                    src="../../assets/image/more.png"
                    alt="more"
                    class="more_img"
                  />
                </div>
              </div>

              <div>
                <el-table
                  :data="tableData"
                  height="300"
                  ref="table"
                  class="index_appeal_table"
                  :header-cell-style="{
                    background: '#0D8EFF',
                    color: '#FFFFFF',
                  }"
                  stripe
                  @cell-mouse-enter="cellMouseEnter"
                  @cell-mouse-leave="tableMove"
                >
                  <el-table-column
                    prop="enterpriseName"
                    label="企业名称"
                    align="left"
                  >
                  </el-table-column>

                  <el-table-column
                    prop="status"
                    label="当前状态"
                    align="center"
                  >
                    <template slot-scope="scope">
                      <span v-if="scope.row.status == 'wait'">待分配</span>
                      <span v-if="scope.row.status == 'distribution'"
                        >已分配</span
                      >
                      <span v-if="scope.row.status == 'receive'">已受理</span>
                      <span v-if="scope.row.status == 'unhandle'"
                        >已进入司法程序</span
                      >
                      <span v-if="scope.row.status == 'finish'">已清偿</span>
                      <span v-if="scope.row.status == 'withdraw'"
                        >不属于受理范围</span
                      >
                      <span v-if="!scope.row.status">***</span>
                    </template>
                  </el-table-column>

                  <el-table-column
                    prop="createTime"
                    label="诉求日期"
                    align="right"
                  >
                  </el-table-column>
                </el-table>
              </div>
            </div>

            <div class="width_calc">
              <div class="flex align_start jus_content bgfff">
                <div class="width" style="height: 380px">
                  <div class="flex align_center relative">
                    <div
                      class="size_18 weight marl_12"
                      @click="handleClass(0, 'synergyShow')"
                    >
                      服务联盟
                    </div>

                    <div
                      class="flex align_center cursor last_pos"
                      @click="toTypeServe"
                    >
                      <div class="eze size_14 marr_8 font">更多</div>
                      <img
                        src="../../assets/image/more.png"
                        alt="more"
                        class="more_img"
                      />
                    </div>
                  </div>

                  <div class="mart_30">
                    <el-carousel
                      trigger="click"
                      :autoplay="false"
                      indicator-position="none"
                      arrow="never"
                      height="430px"
                      ref="synergyShow"
                    > -->
      <!-- 服务联盟 -->
      <!-- <el-carousel-item>
                        <div class="index_synergy_over height">
                          <el-table
                            :data="tableAlliance"
                            height="430"
                            ref="tableAlliance"
                            class="index_appeal_table"
                            :header-cell-style="{
                              background: '#0D8EFF',
                              color: '#FFFFFF',
                            }"
                            stripe
                            @cell-mouse-enter="cellMouseAlliance"
                            @cell-mouse-leave="allianceMove"
                          >
                            <el-table-column
                              prop="unit_name"
                              label="单位名称"
                              align="left"
                            >
                            </el-table-column>

                            <el-table-column
                              prop="sectors"
                              label="当前状态"
                              align="left"
                            >
                            </el-table-column>

                            <el-table-column
                              prop="level"
                              label="认定级别"
                              align="left"
                            >
                            </el-table-column>
                          </el-table>
                        </div>
                      </el-carousel-item>
                    </el-carousel>
                  </div>
                </div>
              </div>

              <div class="flex align_start jus_content bgfff">
                <div class="width flex jus_content">
                  <div>
                    <div
                      class="size_18 weight marl_12"
                      style="border-bottom: 5px solid #d7d7d7"
                    >
                      中小企业数据库
                    </div>
                    <img src="../../assets/image/1665632101743.png" alt="" />
                  </div>

                  <div>
                    <div class="size_18 weight marl_12">中小企业数据库</div>
                    <img src="../../assets/image/1665632101743.png" alt="" />
                  </div>

                  <div>
                    <div class="size_18 weight marl_12">中小企业数据库</div>
                    <img src="../../assets/image/1665632101743.png" alt="" />
                  </div>
                </div>
              </div>
            </div> -->

      <!-- <div class="width_calc">
              <div class="flex align_start jus_content">
                <div
                  class="flex align_center index_appeal_box bgcce"
                  @click="toPortal(1)"
                >
                  <div class="marr_70">
                    <div class="d8f size_16 weight">企业诉求</div>
                    <div class="d8f size_16 weight mart_10">意见登记</div>
                  </div>

                  <img
                    src="../../assets/image/6.png"
                    class="index_appeal_pic"
                  />
                </div>

                <div
                  class="flex align_center index_appeal_box bgffe"
                  @click="toPortal(2)"
                >
                  <div class="marr_70">
                    <div class="ffa size_16 weight">保障款项</div>
                    <div class="ffa size_16 weight mart_10">支付登记</div>
                  </div>

                  <img
                    src="../../assets/image/7.png"
                    class="index_appeal_pic"
                  />
                </div>
              </div>

              <div class="mart_30">
                <div
                  class="bgfff"
                  style="padding: 16px 20px; border-radius: 6px"
                >
                  <div class="flex align_center jus_content">
                    <div class="flex align_center">
                      <div
                        class="index_declare_mintag cursor"
                        :class="{ index_declare_mintag1: appealShow == 0 }"
                        @click="handleClass(0, 'appealShow')"
                      >
                        通知文件
                      </div>

                      <div
                        class="index_declare_mintag cursor"
                        :class="{ index_declare_mintag1: appealShow == 1 }"
                        @click="handleClass(1, 'appealShow')"
                      >
                        工作动态
                      </div>
                    </div>

                    <div class="flex align_center cursor" @click="toAppealMore">
                      <div class="eze size_14 marr_8 font">更多</div>
                      <img
                        src="../../assets/image/more.png"
                        alt="more"
                        class="more_img"
                      />
                    </div>
                  </div>

                  <div class="mart_30">
                    <el-carousel
                      trigger="click"
                      :autoplay="false"
                      indicator-position="none"
                      arrow="never"
                      height="353px"
                      ref="appealShow"
                    >
                      < 通知文件 -->
      <!--  <el-carousel-item>
                        <div>
                          <div
                            v-for="item in appealList.inform"
                            class="
                              index_crad_msg
                              flex
                              align_center
                              jus_content
                              font
                              cursor
                            "
                            @click="toAppeal(item.id, 2)"
                          >
                            <div class="flex align_center width_75">
                              <div class="index_radius marr_12"></div>
                              <div
                                class="text_line1 width size_15"
                                :title="item.title"
                              >
                                {{ item.title }}
                              </div>
                            </div>
                            <div>{{ item.createTime }}</div>
                          </div>
                        </div>
                      </el-carousel-item>

                      <!-- 工作动态 -->
      <!-- <el-carousel-item>
                        <div>
                          <div
                            v-for="item in appealList.work"
                            class="
                              padb_20
                              flex
                              align_center
                              jus_content
                              font
                              cursor
                            "
                            @click="toAppeal(item.id, 1)"
                          >
                            <div class="flex align_center width_75">
                              <div class="index_radius marr_12"></div>
                              <div
                                class="text_line1 width size_15"
                                :title="item.title"
                              >
                                {{ item.title }}
                              </div>
                            </div>
                            <div>{{ item.createTime }}</div>
                          </div>
                        </div>
                      </el-carousel-item>
                    </el-carousel>
                  </div>
                </div>
              </div> -->
      <!-- </div> -->
      <!-- </div>
        </div>
      </section> -->

      <!-- <div class="section"> -->
      <!-- 协同服务 -->
      <!-- <section class="index_synergy padtb_15 section">
          <div class="max_width min_width">
            <div class="flex column align_center marb_30">
              <div class="ttt size_22 weight">服务产品</div>
              <div class="index_line"></div>
            </div>
            <div></div>
            <div
              class="flex"
              style="justify-content: space-around; flex-wrap: wrap"
            >
              <div style="width: 25%; padding: 10px 0px">
                <div
                  style="
                    width: 100px;
                    height: 100px;
                    background-color: pink;
                    margin: 0 auto;
                  "
                ></div>
                <div
                  style="
                    width: 140px;
                    height: 30px;
                    font-size: 20px;
                    font-weight: 500;
                    text-align: center;
                    color: rgb(51, 51, 51);
                    line-height: 30px;
                    margin: 0 auto;
                    /* margin: 10px 0px; */
                  "
                >
                  信息服务
                </div>
                <div
                  style="
                    width: 140px;
                    border: 1px solid blue;
                    color: blue;
                    line-height: 40px;
                    text-align: center;
                    height: 40px;
                    border-radius: 5px;
                    margin: 0 auto;
                  "
                >
                  查看专区
                </div>
              </div>
            </div> -->
      <!-- 地图 -->
      <!-- <div class="flex align_start jus_content bgfff pad_30">
								<div class="width_calc" style="height: 446px;">
									<baidu-map :center="center" :zoom="zoom" @ready="handler" style="height: 100%;"
										:scroll-wheel-zoom='true'
										v-if="governmentCity.municipal.length != 0 || governmentCity.areaGovernment.length != 0 || governmentCity.link.length != 0"> -->
      <!--比例尺控件-->
      <!-- <bm-scale anchor="BMAP_ANCHOR_BOTTOM_LEFT"></bm-scale> -->
      <!--缩放控件-->
      <!-- <bm-navigation anchor="BMAP_ANCHOR_TOP_LEFT"></bm-navigation> -->
      <!-- </baidu-map>
								</div>

								<div class="width_calc">
									<div class="flex align_center">
										<div class="index_synergy_tag cursor"
											:class="{'index_synergy_tag1': synergyShow == 0}"
											@click="handleClass(0, 'synergyShow')">省级政府部门网站</div>
										<div class="index_synergy_tag cursor"
											:class="{'index_synergy_tag1': synergyShow == 1}"
											@click="handleClass(1, 'synergyShow')">市州政府部门网站</div>
										<div class="index_synergy_tag cursor"
											:class="{'index_synergy_tag1': synergyShow == 2}"
											@click="handleClass(2, 'synergyShow')">友情链接</div>
									</div>

									<div class="mart_30">
										<el-carousel trigger="click" :autoplay="false" indicator-position='none'
											arrow='never' height="386px" ref="synergyShow"> -->
      <!-- 市级政府部门网站 -->
      <!-- <el-carousel-item>
												<div class="index_synergy_over height">
													<div class="flex align_start width flex_wrap">
														<div class="flex align_center width_50 padb_20 cursor font"
															v-for="item in governmentCity.municipal"
															@click="setLocal(item)">
															<div class="index_radius marr_12"></div>
															<div class="text_line1 width size_15" :title="item.name"
																:style="{'color': center.lng == item.lnt && center.lat == item.lat ? '#0D8EFF' : ''}">
																{{item.name}}
															</div>
														</div>
													</div>
												</div>
											</el-carousel-item> -->

      <!-- 各区政府网站 -->
      <!-- <el-carousel-item>
												<div class="index_synergy_over height">
													<div class="flex align_start width flex_wrap">
														<div class="flex align_center width_50 padb_20 cursor font"
															v-for="item in governmentCity.areaGovernment"
															@click="setLocal(item)"
															:style="{'color': center.lng == item.lnt && center.lat == item.lat ? '#0D8EFF' : ''}">
															<div class="index_radius marr_12"></div>
															<div class="text_line1 width size_15" :title="item.name">
																{{item.name}}
															</div>
														</div>
													</div>
												</div>
											</el-carousel-item> -->

      <!-- 各区政府网站 -->
      <!-- <el-carousel-item>
												<div class="index_synergy_over height">
													<div class="flex align_start width flex_wrap">
														<div class="flex align_center width_50 padb_20 cursor font"
															v-for="item in governmentCity.link" @click="setLocal(item)"
															:style="{'color': center.lng == item.lnt && center.lat == item.lat ? '#0D8EFF' : ''}">
															<div class="index_radius marr_12"></div>
															<div class="text_line1 width size_15" :title="item.name">
																{{item.name}}
															</div>
														</div>
													</div>
												</div>
											</el-carousel-item>
										</el-carousel>
									</div>
								</div>
							</div> -->

      <!-- 新  服务 专家-->
      <!-- <div class="flex align_start jus_content bgfff pad_30">
              <div class="width" style="height: 600px">
                <div class="flex align_center jus_center relative">
                  <div class="flex align_center">
                    <div
                      class="index_synergy_tag cursor marr_50"
                      :class="{ index_synergy_tag1: synergyShow == 0 }"
                      @click="handleClass(0, 'synergyShow')"
                    >
                      服务联盟
                    </div>
                    <div
                      class="index_synergy_tag cursor"
                      :class="{ index_synergy_tag1: synergyShow == 1 }"
                      @click="handleClass(1, 'synergyShow')"
                    >
                      专家库
                    </div>
                  </div>

                  <div
                    class="flex align_center cursor last_pos"
                    @click="toTypeServe"
                  >
                    <div class="eze size_14 marr_8 font">更多</div>
                    <img
                      src="../../assets/image/more.png"
                      alt="more"
                      class="more_img"
                    />
                  </div>
                </div>

                <div class="mart_30">
                  <el-carousel
                    trigger="click"
                    :autoplay="false"
                    indicator-position="none"
                    arrow="never"
                    height="430px"
                    ref="synergyShow"
                  >
                    <!- 服务联盟 -->
      <!--  <el-carousel-item>
                      <div class="index_synergy_over height">
                        <el-table
                          :data="tableAlliance"
                          height="430"
                          ref="tableAlliance"
                          class="index_appeal_table"
                          :header-cell-style="{
                            background: '#0D8EFF',
                            color: '#FFFFFF',
                          }"
                          stripe
                          @cell-mouse-enter="cellMouseAlliance"
                          @cell-mouse-leave="allianceMove"
                        >
                          <el-table-column
                            prop="unit_name"
                            label="单位名称"
                            align="center"
                          >
                          </el-table-column>

                          <el-table-column
                            prop="contacts"
                            label="联系人"
                            align="center"
                          >
                          </el-table-column>

                          <el-table-column
                            prop="sectors"
                            label="服务领域"
                            align="center"
                          >
                          </el-table-column>

                          <el-table-column
                            prop="level"
                            label="认定级别"
                            align="center"
                          >
                          </el-table-column>
                        </el-table>
                      </div>
                    </el-carousel-item> -->

      <!-- 专家库 -->
      <!--<el-carousel-item>
                      <div class="index_synergy_over height">
                        <el-table
                          :data="tableExpert"
                          height="430"
                          ref="tableExpert"
                          class="index_appeal_table"
                          :header-cell-style="{
                            background: '#0D8EFF',
                            color: '#FFFFFF',
                          }"
                          stripe
                          @cell-mouse-enter="cellMouseExpert"
                          @cell-mouse-leave="expertMove"
                        >
                          <el-table-column
                            prop="unit_name"
                            label="姓名"
                            align="center"
                          >
                          </el-table-column>

                          <el-table-column
                            prop="contacts"
                            label="专业"
                            align="center"
                          >
                          </el-table-column>

                          <el-table-column
                            prop="sectors"
                            label="工作单位"
                            align="center"
                          >
                          </el-table-column>

                          <el-table-column
                            prop="level"
                            label="职称/职务"
                            align="center"
                          >
                          </el-table-column>
                        </el-table>
                      </div>
                    </el-carousel-item>
                  </el-carousel>
                </div>
              </div>
            </div> -->
      <!-- </div> -->
      <!-- </section> -->
      <!-- <div class="section">
            <section class="index_appeal padtb_30 section">
              <div class="max_width min_width section">
                <div class="flex align_start jus_content">
                  <div>
                    <div class="ttt size_22 weight" style="padding: 20px">
                      服务器数据推送
                    </div>
                    <div
                      style="
                        width: 700px;
                        height: 130px;
                        background: #e7e7e7;
                        border-radius: 10px;
                        margin: 10px 0px;
                      "
                    ></div>
                    <div
                      style="
                        width: 700px;
                        height: 130px;
                        background: #e7e7e7;
                        border-radius: 10px;
                        margin: 10px 0px;
                      "
                    ></div>
                  </div>

                  <div>
                    <div class="ttt size_22 weight" style="padding: 20px">
                      活动排期
                    </div>

                    <div class="" style="width: 700px">
                      <el-timeline>
                        <el-timeline-item
                          v-for="(activity, index) in activities"
                          :key="index"
                          :timestamp="activity.timestamp"
                        >
                          {{ activity.content }}
                        </el-timeline-item>
                      </el-timeline>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div> -->
      <!-- </div> -->

      <!-- <div class="section">
        <section class="index_appeal padtb_30 section">
          <div class="max_width min_width section">
            <div class="flex align_start jus_content">
              <div>
                <div class="ttt size_22 weight" style="padding: 20px">
                  服务器数据推送
                </div>
                <div
                  style="
                    width: 700px;
                    height: 130px;
                    background: #e7e7e7;
                    border-radius: 10px;
                    margin: 10px 0px;
                  "
                ></div>
                <div
                  style="
                    width: 700px;
                    height: 130px;
                    background: #e7e7e7;
                    border-radius: 10px;
                    margin: 10px 0px;
                  "
                ></div>
              </div>

              <div>
                <div class="ttt size_22 weight" style="padding: 20px">
                  活动排期
                </div>

                <div class="" style="width: 700px">
                  <el-timeline>
                    <el-timeline-item
                      v-for="(activity, index) in activities"
                      :key="index"
                      :timestamp="activity.timestamp"
                    >
                      {{ activity.content }}
                    </el-timeline-item>
                  </el-timeline>
                </div>
              </div>
            </div>
          </div>
        </section>

        <!-- 尾部 -->
      <!-- <div class="index_footer"> -->
      <!-- <app-footer @phone="getPhone" @statistical="getTongji" /> -->
      <app-footer @phone="getPhone" />
      <!-- </div> -->
      <!-- </div> -->
      <!-- </full-page> -->
    </div>

    <el-backtop target=".index">
      <i class="el-icon-caret-top"></i>
    </el-backtop>

    <div class="totop" v-show="toTopShow" @click="toTop()">返回顶部</div>

    <!-- 右侧导航栏 -->
    <!-- <app-rignavigation @scrollMove="scrollMove" /> -->
  </div>
</template>

<script>
// category_list接口: type  类型 1热点资讯 2通知公告 3所属区域 4政策分类 5文件级别 6发文部门 7政策类型 8行业类型 9所属年份
import { lazyAMapApiLoaderInstance } from "vue-amap";
import Header from "@/components/Header/header.vue";
import Footer from "@/components/Footer/footer.vue";
import RigNaviagtion from "@/components/RigNaviagtion/rignaviagtion.vue";
import axios from "axios";
import AMapLoader from "@amap/amap-jsapi-loader";
// import { async } from "q";
export default {
  data() {
    return {
      banner_img: {},
      map: "", // 地图
      pointSimplifier: null,
      district: null,
      currentAreaNode: null,
      num1: "",
      num2: "",
      num3: "",
      num4: "",
      num5: "",
      num6: "",
      num7: "",
      counts: "",

      toTopShow: false,

      srcoll: 0,

      options: {
        licenseKey: "OPEN-SOURCE-GPLV3-LICENSE",
        //是否显示导航，默认为false
        navigation: true,
        // 内容超过满屏时是否显示滚动条
        scrollOverflow: false,
        // section选择器
        sectionSelector: ".section",
        // anchors: ["page1", "page2", "page3"]
      },
      offsetHeight: 0,
      statistical: {},
      dynamicState: 1,
      mapStyle: {
        // 地图样式
        styleJson: [
          {
            featureType: "all",
            elementType: "geometry",
            stylers: {
              hue: "#007fff",
              saturation: 89,
            },
          },
          {
            featureType: "water",
            elementType: "all",
            stylers: {
              color: "#ffffff",
            },
          },
        ],
      },
      // 初始地图经纬度
      center: {
        lng: 0,
        lat: 0,
      },
      zoom: 18,
      keyword: "",
      topkeyword: "",
      cardShow: 0,
      appealShow: 0,
      synergyShow: 0,
      // 大轮播图
      swiperList: [],
      // 小轮播图
      swipersmallList: [],
      // 热点动态
      hotList: [],
      // 通知公告
      noticeList: [],
      // 政策分类
      policyClass: [],
      policyList: {
        // 12
        laws: [],
        // 7
        unscramble: [],
        // 4
        declare: [],
      },
      // 服务联盟 表格
      tableAlliance: [],
      // 专家库 表格
      tableExpert: [],
      // 专精特新
      declareList: [],
      // 双创载体
      doubleList: [],
      // 专项资金
      specialList: [],
      // 青海省工业小微企业融资服务 分类
      procate: [],
      // 青海省工业小微企业融资服务 金融产品
      productList: [],
      tableData: [],
      finCount1: "",
      finCount2: "",
      finCount3: "",

      activities: [
        {
          content: "活动按期开始",
          timestamp: "2018-04-15",
        },
        {
          content: "通过审核",
          timestamp: "2018-04-13",
        },
        {
          content: "创建成功",
          timestamp: "2018-04-11",
        },
      ],
      finCount4: "",
      finCount5: "",
      finCount6: "",
      finCount7: "",
      // 最新融资动态
      financialList: [
        {
          name: "工商银行青海分行",
          company_name: "青海中钛青锻装备制造有限公司",
          accept_quota: "8000",
          accept_time: "2021.05.09",
        },
        {
          name: "光大银行西宁分行",
          company_name: "青海中钛青锻装备制造有限公司",
          accept_quota: "3000",
          accept_time: "2021.09.10",
        },
        {
          name: "中信银行西宁分行",
          company_name: "青海鑫邦线缆有限公司",
          accept_quota: "500",
          accept_time: "2021.10.29",
        },
        {
          name: "工商银行青海分行",
          company_name: "青海鑫邦线缆有限公司",
          accept_quota: "300",
          accept_time: "2021.02.21",
        },
        {
          name: "建设银行青海分行",
          company_name: "青海鑫邦线缆有限公司",
          accept_quota: "100",
          accept_time: "2021.08.29",
        },
        {
          name: "中信银行西宁分行",
          company_name: "青海恩泽农业技术有限公司",
          accept_quota: "500",
          accept_time: "2021.07.03",
        },
        {
          name: "光大银行西宁分行",
          company_name: "青海新丁香粮油有限责任公司",
          accept_quota: "1000",
          accept_time: "2021.05.29",
        },
        {
          name: "中信银行西宁分行",
          company_name: "欧耐特线缆集团有限公司",
          accept_quota: "500",
          accept_time: "2021.06.18",
        },
        {
          name: "建设银行青海分行",
          company_name: "海东市旭格光电科技有限公司",
          accept_quota: "500",
          accept_time: "2021.01.11",
        },
        {
          name: "光大银行西宁分行",
          company_name: "青海诺德新材料有限公司",
          accept_quota: "5000",
          accept_time: "2021.09.23",
        },
      ],
      // 诉求意见及保障支付
      appealList: {
        // 工作动态
        work: [],
        // 通知文件
        inform: [],
      },
      // 协同服务
      governmentCity: {
        // 市级政府部门网站
        municipal: [],
        // 各区政府网站
        areaGovernment: [],
        // 友情链接
        link: [],
      },
      timer: "",
      indexlist: "",
      ftimer: "",
      atimer: "",
      etimer: "",
      map: "",
      options: [],
      phone: "",
      policy: [
        { num: 1002, type: "条", title: "发布惠企政策", color: "#2D5DFE" },
        { num: 2334, type: "家", title: "服务机构注册量", color: "#FF6B21" },
        { num: 4576, type: "条", title: "服务产品发布量", color: "#5B2EF6" },
        { num: 6688, type: "次", title: "已展开服务活动", color: "#FF2B2B" },
        { num: 9895, type: "家", title: "企业入驻量", color: "#FFA00E" },
        { num: 6653, type: "次", title: "访问数量", color: "#39E5CB" },
      ],
      fagui: false,
      zhengce: true,
      area: [],
      cityarr: [],
    };
  },
  components: {
    "app-header": Header,
    "app-footer": Footer,
    "app-rignavigation": RigNaviagtion,
  },
  watch: {
    srcoll() {
      if (this.srcoll > 400) {
        this.toTopShow = true;
      } else {
        this.toTopShow = false;
      }
    },
  },
  created() {
    this.getcatelist();
    this.getSetting();
  },
  methods: {
    //   // 初始化地图相关实例
    //   initMap() {
    //   const that = this;
    //   lazyAMapApiLoaderInstance.load().then(() => {
    //     that.map = new AMap.Map("test-map", {
    //       zooms: [3, 18], // 地图缩放范围
    //       center: new AMap.LngLat(116.397428, 39.90923),
    //     });

    //     // 加载海量点组件
    //     AMapUI.loadUI(["misc/PointSimplifier"], (PointSimplifier) => {
    //       if (!PointSimplifier.supportCanvas) {
    //         alert("当前环境不支持 Canvas！");
    //         return;
    //       }
    //       // 创建组件实例
    //       that.pointSimplifier = new PointSimplifier({
    //         map: that.map,
    //         // data是海量点的坐标，下面是随机产生点坐标的方法
    //         // 返回要显示的点坐标
    //         autoSetFitView: false, //禁止自动更新地图视野
    //         zIndex: 110,
    //         getPosition: function(item) {
    //          // item  为 setMapData()方法里mapData里的每一项
    //           if (!item) {
    //             return null;
    //           }
    //           var parts = item.split(",");

    //           //返回经纬度 [lng, lat]
    //           return [parseFloat(parts[0]), parseFloat(parts[1])];
    //         },
    //         getHoverTitle: function(dataItem, idx) {
    //           return idx + ": " + dataItem;
    //         },
    //         renderOptions: {
    //           //点的样式
    //           pointStyle: {
    //             width: 6,
    //             height: 6,
    //             fillStyle: "rgba(153, 0, 153, 0.38)",
    //           },
    //           //鼠标hover时的title信息
    //           hoverTitleStyle: {
    //             position: "top",
    //           },
    //         },
    //       });
    //     });

    //     //加载相关组件
    //     AMapUI.load(["ui/geo/DistrictCluster", "lib/utils"], function(
    //       DistrictCluster,
    //       utils
    //     ) {
    //       // 定义行政区划聚合实例
    //       that.district = new DistrictCluster({
    //         map: that.map, //所属的地图实例
    //         //返回数据项中的经纬度位置
    //         getPosition: function(item) {
    //           if (!item) {
    //             return null;
    //           }
    //           var parts = item.split(",");
    //           //返回经纬度 [lng, lat]
    //           return [parseFloat(parts[0]), parseFloat(parts[1])];
    //         },
    //       });
    //       that.setMapData()
    //     });
    //   });
    // },
    // setMapData() {
    //   // 地图点的数据
    //   const mapData = [
    //     "101.778916,36.623178",
    //   ];

    //   this.pointSimplifier.setData(mapData);
    //   this.district.setData(mapData);
    // },

    srcollShow() {
      this.srcoll =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
    },

    toTop() {
      this.toTopSpeed = setInterval(() => {
        document.documentElement.scrollTop =
          document.documentElement.scrollTop - 20;
        //通过改变数字实现动画延迟滚动
        if (this.srcoll < 10) {
          clearInterval(this.toTopSpeed);
        }
      }, 1);
    },

    gospecial(type) {
      // this.$router.push("/special");
      if (type == 1) {
        this.$router.push({ path: "/special", query: { type: 1 } });
      } else if (type == 2) {
        this.$router.push({ path: "/special", query: { type: 2 } });
      }
    },
    getSetting() {
      this.$post("/setting", {}).then((res) => {
        this.indexlist = res.result;
      });
    },
    goneed() {
      localStorage.setItem("poin", "3");
      this.$router.push("/demand");
    },
    gomarket() {
      localStorage.setItem("poin", "3");
      this.$router.push("/feed");
    },
    // 图片外链
    goweb(type, url) {
      if (type === 1) {
        //三张图片
        window.open("http://shenbao.smeqh.cn/web/index.html", "_blank");
      } else if (type === 2) {
        window.open("http://rzx.smeqh.cn/web/#/", "_blank");
      } else if (type === 3) {
        window.open("http://sq.smeqh.cn/", "_blank");
      } else if (type === 4) {
        //工业信息化
        window.open(
          "http://ucenter.miit.gov.cn/login.jsp?toUrl=https%3A%2F%2Fythzxfw.miit.gov.cn%2Fuser-center%2Frest%2FSSOCtrl%2Flogin%3FfullPath%3D%2Findex",
          "_blank"
        );
      } else if (type === 5 && url) {
        //大赛
        window.open(url, "_blank");
        // window.open("http://www.qh96868.cn/cms/index/index", "_blank");
      } else if (type === 6) {
        this.$router.push("/database");
      } else if (type === 7) {
        let index = localStorage.logintype;
        if (index == "2") {
          this.$router.push({ path: "/personalcenter", query: { index: "2" } });
        } else {
          if (!localStorage.eleToken) {
            this.$message({
              type: "warning",
              message: "请先登录",
            });
          } else {
            this.$message({
              type: "warning",
              message: "该产品只限服务机构申请",
            });
          }
        }
      }
    },

    //服务产品
    goserveProduct(item) {
      this.$router.push({ path: "/serveProduct", query: { index: item.id } });
    },

    //服务专员
    gocommissioner() {
      this.$router.push("/commissioner");
    },

    gostatus(item) {
      this.dynamicState = item;
    },
    getcatelist() {
      let data = { token: localStorage.eleToken };
      this.$get("cate_list", data).then((res) => {
        this.options = res.result;
      });
    },

    lookzhengce() {
      this.fagui = false;
      this.zhengce = true;
    },
    lookfagui() {
      this.fagui = true;
      this.zhengce = false;
    },
    scrollMove(index) {
      this.$refs.fullpage.api.moveTo(index);
    },
    open() {
      this.$alert(
        `尊敬的用户：为了给您提供更好更顺畅的线上服务，青海省中小企业公共服务平台官网将于9月23至9月25日进行系统维护，期间官网将暂停访问，由此带来的不便请您谅解！如需了解我平台相关动态资讯，请关注“青海省中小企业公共服务”微信公众号。`,
        "关于青海省中小企业公共服务平台官网暂停访问的公告",
        {
          confirmButtonText: "确定",
          callback: (action) => {
            // this.navigateTo();
            // this.$message({
            //   type: "info",
            //   message: `action: ${action}`,
            // });
          },
        }
      );
    },
    // 点击标题跳转
    titleTap(type) {
      if (type == 1) {
        this.$router.push("/lawsregulations");
      }

      if (type == 2) {
        window.open("https://rzx.smeqh.cn", "_blank");
      }

      if (type == 3) {
        window.open("https://sq.smeqh.cn/#/portal/index", "_blank");
      }
    },
    getTongji(data) {
      this.statistical = data;
    },
    getPhone(phone) {
      this.phone = phone;
    },
    // 图片跳转
    toUrl(type) {
      if (type == 1) {
        window.open("http://shenbao.smeqh.cn/web/index.html", "_blank");
      }

      if (type == 2) {
        window.open(" http://www.qh96868.cn/cms/index/index", "_blank");
      }

      if (type == 3) {
        window.open("http://sme.megawise.cn/fuwu/index.html", "_blank");
      }
    },
    // 获取大轮播图
    getBannerList() {
      let data = {
        type_id: 1,
        category: 1,
      };

      this.$get("/banner_list", data).then((res) => {
        if (res.status == 1) {
          this.swiperList = res.result ? res.result : [];
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 获取小轮播图
    getBannersmallList() {
      let data = {
        type_id: 1,
        category: 2,
      };

      this.$get("/banner_list", data).then((res) => {
        if (res.status == 1) {
          this.swipersmallList = res.result ? res.result : [];
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // 获取大轮播图
    getBanner_img() {
      let data = {
        id: "28",
      };

      this.$get("/banner_lst_d", data).then((res) => {
        this.banner_img = res.result;
      });
    },

    // 轮播图跳转
    swiperUrl(url) {
      if (url) {
        window.open(url, "_blank");
      }
    },
    // 获取头部高度
    getHeight(data) {
      this.offsetHeight = data;
    },
    // 地图
    handler({ BMap, map }) {
      this.map = map;
      this.center.lng = this.governmentCity.municipal[0].lnt;
      this.center.lat = this.governmentCity.municipal[0].lat;

      // 创建点标记
      let point = new BMap.Point(this.center.lng, this.center.lat);
      map.centerAndZoom(point, 13);
      let marker = new BMap.Marker(point); // 创建标注

      map.addOverlay(marker); // 将标注添加到地图中
      let circle = new BMap.Circle(point, 6, {
        strokeColor: "Red",
        strokeWeight: 6,
        strokeOpacity: 1,
        Color: "Red",
        fillColor: "#f03",
      });

      // 创建信息窗口
      let opts = {
        width: 200,
        height: 100,
        title: this.governmentCity.municipal[0].name,
      };

      let content = `
				<div class='size_12 f838 mart_10'>地址：${this.governmentCity.municipal[0].address}</div>
				<div class="size_12 f838 mart_10">网址：<a href="${this.governmentCity.municipal[0].url}" class="e9f" target="_blank">${this.governmentCity.municipal[0].url}</a></div>
				`;

      let infoWindow = new BMap.InfoWindow(content, opts);
      // 点标记添加点击事件
      marker.addEventListener("click", function () {
        map.openInfoWindow(infoWindow, point); // 开启信息窗口
      });

      // 在地图上添加点标记
      map.addOverlay(circle);
    },
    // 点击地图标记
    getClickInfo(e) {},
    // 点击设置标记
    setLocal(item) {
      // 清除全部标记
      this.map.clearOverlays();

      this.center.lng = item.lnt;
      this.center.lat = item.lat;

      // 创建点标记
      let point = new BMap.Point(item.lnt, item.lat);
      this.map.centerAndZoom(point, 13);
      let marker = new BMap.Marker(point); // 创建标注

      this.map.addOverlay(marker); // 将标注添加到地图中
      let circle = new BMap.Circle(point, 6, {
        strokeColor: "Red",
        strokeWeight: 6,
        strokeOpacity: 1,
        Color: "Red",
        fillColor: "#f03",
      });

      // 创建信息窗口
      let opts = {
        width: 200,
        height: 100,
        title: item.name,
      };

      let content = `
				<div class='size_12 f838 mart_10'>地址：${item.address}</div>
				<div class="size_12 f838 mart_10">网址：<a href="${item.url}" class="e9f" target="_blank">${item.url}</a></div>
				`;

      let infoWindow = new BMap.InfoWindow(content, opts);
      // 点标记添加点击事件
      marker.addEventListener("click", function () {
        this.map.openInfoWindow(infoWindow, point); // 开启信息窗口
      });

      // 在地图上添加点标记
      this.map.addOverlay(circle);
    },
    handleClass(index, name) {
      if (name == "cardShow") {
        if (index == 0) {
          this.getCore();
        }

        if (index == 1) {
          this.getDouble();
        }

        if (index == 2) {
          // 专项资金暂无
          this.$message.warning("暂无");
          return;
        }
      }

      if (name == "synergyShow") {
        // 清除全部标记  地图
        // this.map.clearOverlays()

        // this.getGovernmentCity(index + 1, index)

        // 新
        if (index == 0) {
          this.getServiceList(1);

          this.cellMouseExpert();
          // this.allianceMove();
        }

        if (index == 1) {
          this.getServiceList(2);

          this.cellMouseAlliance();
          this.expertMove();
        }
      }

      this.$refs[name].setActiveItem(index);
      this[name] = index;
    },
    // 表格滚动
    // tableMove() {
    //   // 拿到表格挂载后的真实DOM
    //   const table = this.$refs.table;
    //   // 拿到表格中承载数据的div元素
    //   const divData = table.bodyWrapper;
    //   // 拿到元素后，对元素进行定时增加距离顶部距离，实现滚动效果(此配置为每100毫秒移动1像素)
    //   this.timer = setInterval(() => {
    //     // 元素自增距离顶部1像素
    //     divData.scrollTop += 1;
    //     // 判断元素是否滚动到底部(可视高度+距离顶部=整个高度)
    //     if (divData.clientHeight + divData.scrollTop == divData.scrollHeight) {
    //       // 重置table距离顶部距离
    //       divData.scrollTop = 0;
    //     }
    //   }, 40);
    // },
    // 最新融资动态滚动
    // financialMove() {
    //   // 拿到表格挂载后的真实DOM
    //   const table = this.$refs.financial;
    //   // 拿到表格中承载数据的div元素
    //   const divData = table.bodyWrapper;
    //   // 拿到元素后，对元素进行定时增加距离顶部距离，实现滚动效果(此配置为每100毫秒移动1像素)
    //   this.ftimer = setInterval(() => {
    //     // 元素自增距离顶部1像素
    //     divData.scrollTop += 1;
    //     // 判断元素是否滚动到底部(可视高度+距离顶部=整个高度)
    //     if (divData.clientHeight + divData.scrollTop == divData.scrollHeight) {
    //       // 重置table距离顶部距离
    //       divData.scrollTop = 0;
    //     }
    //   }, 40);
    // },
    // 服务联盟滚动
    // allianceMove() {
    //   // 拿到表格挂载后的真实DOM
    //   const table = this.$refs.tableAlliance;
    //   // 拿到表格中承载数据的div元素
    //   const divData = table.bodyWrapper;
    //   // 拿到元素后，对元素进行定时增加距离顶部距离，实现滚动效果(此配置为每100毫秒移动1像素)
    //   this.atimer = setInterval(() => {
    //     // console.log(2)
    //     // 元素自增距离顶部1像素
    //     divData.scrollTop += 1;
    //     // 判断元素是否滚动到底部(可视高度+距离顶部=整个高度)
    //     if (divData.clientHeight + divData.scrollTop == divData.scrollHeight) {
    //       // 重置table距离顶部距离
    //       divData.scrollTop = 0;
    //     }
    //   }, 40);
    // },
    // 专家库滚动
    // expertMove() {
    //   // 拿到表格挂载后的真实DOM
    //   const table = this.$refs.tableExpert;
    //   // 拿到表格中承载数据的div元素
    //   const divData = table.bodyWrapper;
    //   // 拿到元素后，对元素进行定时增加距离顶部距离，实现滚动效果(此配置为每100毫秒移动1像素)
    //   this.etimer = setInterval(() => {
    //     // 元素自增距离顶部1像素
    //     divData.scrollTop += 1;
    //     // 判断元素是否滚动到底部(可视高度+距离顶部=整个高度)
    //     if (divData.clientHeight + divData.scrollTop == divData.scrollHeight) {
    //       // 重置table距离顶部距离
    //       divData.scrollTop = 0;
    //     }
    //   }, 40);
    // },
    navigateUrl(url) {
      this.$router.push(url);
    },
    // 热点动态
    getNewsPort() {
      let data = {
        type: 1,
        page: 1,
        limit: 11, //5
      };

      this.$get("/news_list", data).then((res) => {
        this.hotList = res.result;
      });
    },
    // 通知公告
    getNoticePort() {
      let data = {
        type: 2,
        page: 1,
        limit: 11, //5
      };

      this.$get("/news_list", data).then((res) => {
        this.noticeList = res.result;
      });
    },
    // 政策分类
    getPolicyClass() {
      let data = {
        type: 4,
      };

      this.$get("/category_list", data).then((res) => {
        this.policyClass = res.result.splice(0, 3);

        // this.getPolicyList(this.policyClass[0].id, 12);
        this.getPolicyList("", 12);
        this.getPolicyList(this.policyClass[1].id, 7);
        this.getPolicyList(this.policyClass[2].id, 4);
      });
    },
    // 通过政策分类拿到政策列表
    getPolicyList(id, limit) {
      let data = {
        category: id,
        page: 1,
        limit,
      };

      this.$post("/policy_list", data).then((res) => {
        if (limit == 12) {
          this.policyList.laws = res.result ? res.result : [];
        }

        if (limit == 7) {
          this.policyList.unscramble = res.result ? res.result : [];
        }

        if (limit == 4) {
          this.policyList.declare = res.result ? res.result : [];
        }
      });
    },
    // 专精特新
    getCore() {
      axios({
        url: "https://shenbao.smeqh.cn/v1/project_lst",
        data: {
          cate_id: "",
          type: 1,
          page: 1,
          limit: 10,
          keyword: "",
          startTime: "",
          endTime: "",
        },
        method: "POST",
      }).then((res) => {
        if (res.status == 1) {
          this.declareList = res.result ? res.result : [];
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 专精特新 跳转
    toDeclare(id) {
      let url = `https://shenbao.smeqh.cn/web/details-222.html?name=${id}`;

      window.open(url, "_blank");
    },
    // 双创载体
    getDouble() {
      axios({
        url: "https://shenbao.smeqh.cn/v1/project_lst",
        data: {
          cate_id: "",
          type: 2,
          page: 1,
          limit: 10,
          keyword: "",
          startTime: "",
          endTime: "",
        },
        method: "POST",
      }).then((res) => {
        if (res.status == 1) {
          this.doubleList = res.result ? res.result : [];
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 双创载体 跳转
    toDouble(id) {
      let url = `https://shenbao.smeqh.cn/web/details-223.html?name=${id}`;

      window.open(url, "_blank");
    },
    // 跳转政策法规
    toLaws(type) {
      if (type == "search") {
        if (this.keyword == "") {
          this.$message.warning("请输入关键词搜索");
          return;
        }

        this.$router.push({
          path: "/lawsregulations",
          query: {
            keyword: this.keyword,
          },
        });
      } else {
        let id =
          type == "first"
            ? this.policyClass[0].id
            : type == "second"
            ? this.policyClass[1].id
            : type == "third"
            ? this.policyClass[2].id
            : "";

        this.$router.push({
          path: "/lawsregulations",
          query: {
            ids: id,
          },
        });
      }
    },
    // 跳转热点动态详情
    toHotdetails(id) {
      this.$router.push({
        path: "/hotdetails",
        query: {
          ids: id,
        },
      });
    },
    // 跳转通知公告详情
    toAnnouncedetails(id) {
      this.$router.push({
        path: "/announcedetails",
        query: {
          ids: id,
        },
      });
    },
    // 跳转政策详情
    toLawsDetails(id) {
      this.$router.push({
        path: "/lawsdetails",
        query: {
          ids: id,
        },
      });
    },
    //enterprise_count
    getenterprise() {
      let data = {
        type: 1,
      };
      this.$get("/enterprise_count", data).then((res) => {
        this.counts = res.result;
        let cityvalue = [];
        let city = [];
        console.log(this.area, "this.area111");
        let cityarr = [];
        let cityid 
          this.area.map((item) => {
            for (const i in this.counts) {
               cityid   = "city" + item.id;
            if (i == cityid && this.counts[i]) {
              cityvalue.push(this.counts[i]);
              cityarr.push(item.name);
              city.push({
                name: item.name,
                value: this.counts[i],
              });
            }
          }
          });
      

        console.log(cityarr, "cityarr");

        //使用init初始化
        let that = this;

        const myCharts = this.$echarts.init(this.$refs.myCharts);
        var values = cityarr;
        var sum = cityarr;
        var legendData = cityarr;
        var colorList = [
          "#FFDC44",
          "#00FFA0",
          "#FFB130",
          "#304EFF",
          "#4BE0FC",
          "#ff8e43",
          "#1692F4",
          "#6efbbf",

          //  "#6a89e2",
          //  "#3862D8",
        ];
        // var titletext = "“专精特新”\n 中小企业总户数 110 ";
        var titletext = `“专精特新”\n 中小企业${this.counts.amount}户`;
        let option = {
          backgroundColor: "#fff",
          title: {
            lineHeight: 50,
            text: titletext,
            textAlign: "center",
            textStyle: {
              fontSize: 18,
              color: "#000",
            },
            left: "25%",
            top: "40%",
          },
          tooltip: {
            trigger: "item",
            borderColor: "rgba(255,255,255,.3)",
            backgroundColor: "rgba(13,5,30,.6)",
            borderWidth: 1,
            padding: 5,
            textStyle: {
              color: "#fff",
            },
          },
          legend: {
            type: "scroll",
            orient: "vertical",
            right: "15px",
            // align: "auto",
            // top: "middle",
            itemGap: 15,
            itemHeight: 10,
            textStyle: {
              fontSize: 16,
              color: "#000",
            },
            data: legendData,
            formatter: function (name) {
              for (var i = 0; i < legendData.length; i++) {
                if (legendData[i] == name) {
                  return `${name}  ${cityvalue[i]}户`;
                }
              }
            },
          },
          series: [
            {
              type: "pie",
              z: 3,
              right: "100px",
              center: ["30%", "50%"],
              radius: ["80%", "55%"],
              clockwise: true,
              avoidLabelOverlap: true,
              hoverOffset: 15,
              itemStyle: {
                normal: {
                  color: function (params) {
                    return colorList[params.dataIndex];
                  },
                },
              },
              label: {
                show: false,
              },
              data: city,
            },
            {
              name: "外边框",
              type: "pie",
              right: "100px",
              clockWise: false, //顺时加载
              hoverAnimation: false, //鼠标移入变大
              center: ["30%", "40%"],
              radius: ["50%", "50%"],
              label: {
                normal: {
                  show: false,
                },
              },
              // data: [
              //   {
              //     value: 1,
              //     name: "",
              //     itemStyle: {
              //       normal: {
              //         borderWidth: 5,
              //         borderColor: "rgba(11,82,99,.5)",
              //       },
              //     },
              //   },
              // ],
            },
          ],
        };

        myCharts.setOption(option);
      });
    },
    getcity() {
      let data = {
        token: localStorage.eleToken,
      };
      this.$get("area_select", data).then((res) => {
        this.area = res.result;
        this.initChart();
        this.getenterprise();
      });
    },
    initChart() {
      let values = [];
      let data = {
        type: 2,
      };
      this.$get("/enterprise_count", data).then((res) => {
        this.counts = res.result;

        let cityid;
        this.area.map((item) => {
          cityid = "city" + item.id;

          for (const i in this.counts) {
            if (i == cityid) {
              this.cityarr.push(item.name);
              values.push(this.counts[i]);
            }
          }
        });

        console.log(this.cityarr, "this.area222");

        // for (let i = 0; i < this.area.length; i++) {
        //   let cityid = 'city'+this.area[i]
        //   console.lo('city'+this.area[i],'cityid')
        //   debugger
        //   if(cityid == item){
        //     values.push(res.result.cityid)
        //   }
        // }

        const myChart = this.$echarts.init(this.$refs.myCharts3);
        let e = document.body.offsetWidth;
        let w = window.innerWidth;

        let option = {
          //你的代码
          // backgroundColor: '#081736',
          tooltip: {
            trigger: "axis",
            // backgroundColor: "rgba(17,95,182,0.5)",
            textStyle: {
              color: "#000",
            },
            axisPointer: {
              type: "shadow",
            },
            formatter: function (params) {
              return params[0].name + ":" + params[0].value + "户";
            },
          },
          legend: {
            // data: ['城市'],

            align: "left",
            right: 0,
            top: 80,
            textStyle: {
              color: "rgba(255,255,255,0.8)",
            },
            itemWidth: 13,
            itemHeight: 10,
            itemGap: 25,
          },
          grid: {
            top: "13%",
            left: "0%",
            right: "12%",
            bottom: "10%",
            containLabel: true,
          },
          xAxis: [
            {
              type: "category",
              // data: [
              //   "西宁市",
              //   "海东市",
              //   "海西市",
              //   "海南州",
              //   "海北州",
              //   "黄南州",
              //   "果洛州",
              //   "玉树州",
              // ],
              data: this.cityarr,
              axisLine: {
                show: true,

                lineStyle: {
                  color: "#063374",
                  width: 1,
                  type: "solid",
                },
              },
              axisTick: {
                show: false,
              },
              axisLabel: {
                show: true,
                interval: 0, //横轴信息全部显示
                rotate: -15, //-30度角倾斜显示
                textStyle: {
                  color: "#000",
                  // fontSize: 14
                  fontSize: (e / w) * 12,
                },
              },
            },
          ],
          yAxis: [
            {
              //  name: '户',
              //  nameTextStyle: {
              //      color: 'rgba(255,255,255,0.8)',
              //      fontSize:16
              //  },
              type: "value",
              axisLabel: {
                formatter: "{value} ",
                textStyle: {
                  color: "#000",
                  // fontSize: 14
                  fontSize: (e / w) * 12,
                },
              },
              axisTick: {
                show: false,
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#063374",
                  width: 1,

                  type: "solid",
                },
              },
              splitLine: {
                lineStyle: {
                  color: "#063374",
                  type: "",
                },
              },
            },
          ],
          series: [
            {
              name: "",
              type: "bar",
              // data: [27221, 13105, 4761, 2853, 2349, 1585, 696, 1250],
              data: values,
              barWidth: 10, //柱子宽度
              barGap: 0.5, //柱子之间间距
              itemStyle: {
                normal: {
                  color: "#00CCFF",
                  // color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  //   {
                  //     offset: 0,
                  //     color: "#00CCFF",
                  //   },
                  //   {
                  //     offset: 1,
                  //     color: "#00033A",
                  //   },
                  // ]),
                  opacity: 1,
                },
              },
              //  label: {
              //     show: true,
              //     position: 'top',
              //     valueAnimation: true, // 开启动画效果
              //     formatter: function (params) {
              //         return params.value+ '户';
              //     }
              // }
            },
          ],
        };

        if (option && typeof option === "object") {
          myChart.setOption(option, true);
          refreshChart(myChart, option);
          var index4 = 0; //播放所在下标
          var mTime = setInterval(function () {
            myChart.dispatchAction({
              type: "showTip",
              seriesIndex: 0,
              dataIndex: index4,
            });
            index4++;
            if (index4 >= 8) {
              index4 = 0;
            }
          }, 8000);
        }

        // 刷新图表
        function refreshChart(chartID, option) {
          setInterval(function () {
            chartID.clear();
            chartID.setOption(option, true);
          }, 10000);
        }

        myChart.setOption(option);
        option && myChart.setOption(option);
        window.addEventListener("resize", () => {
          myChart.resize();
        });
      });
    },

    // 青海省工业小微企业融资服务 金融产品
    getProList() {
      axios({
        url: "https://rzx.smeqh.cn/v1/product_list",
        data: {
          page: 1,
          limit: 5,
        },
        method: "POST",
      }).then((res) => {
        if (res.status == 1) {
          this.productList = res.result ? res.result : [];
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 青海省工业小微企业融资服务 跳转
    toProductsdetail(id) {
      let url = `https://rzx.smeqh.cn/web/#/productsdetail?ids=${id}&type=2`;

      window.open(url, "_blank");
    },
    // 青海省工业小微企业融资服务 最新融资动态
    getFinlist() {
      axios({
        url: "https://rzx.smeqh.cn/v1/index_list",
        data: {},
        method: "POST",
      }).then((res) => {
        if (res.status == 1) {
          this.finCount1 = res.result.count[0].cnt
            ? res.result.count[0].cnt
            : 0;
          this.finCount2 = res.result.count[1].cnt
            ? res.result.count[1].cnt
            : 0;
          this.finCount3 = res.result.count[2].cnt
            ? res.result.count[2].cnt
            : 0;
          this.finCount4 = res.result.count[3].cnt
            ? res.result.count[3].cnt
            : 0;
          this.finCount5 = res.result.count[4].cnt
            ? res.result.count[4].cnt
            : 0;
          this.finCount6 = res.result.count[5].cnt
            ? res.result.count[5].cnt
            : 0;
          this.finCount7 = res.result.count[6].cnt
            ? res.result.count[6].cnt
            : 0;

          this.financialList = !res.result.financial_list
            ? this.financialList
            : res.result.financial_list.concat(this.financialList);
          this.financialList.map((item) => {
            if (item.company_name.length > 5) {
              item.company_name = "*****" + item.company_name.substring(5);
            } else {
              item.company_name = "*****";
            }
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    cellMouseEnter() {
      clearInterval(this.timer);
    },
    cellFMouseEnter() {
      clearInterval(this.ftimer);
    },
    cellMouseAlliance() {
      clearInterval(this.atimer);
    },
    cellMouseExpert() {
      clearInterval(this.etimer);
    },
    toPortal(type) {
      if (type == 1) {
        let url = `https://sq.smeqh.cn/#/portal/enterpriseAppeal`;
        window.open(url, "_blank");
      }

      if (type == 2) {
        let url = `https://sq.smeqh.cn/#/portal/paymentAppeal`;
        window.open(url, "_blank");
      }

      if (type == 3) {
        let url = `https://sq.smeqh.cn/#/portal/index`;
        window.open(url, "_blank");
      }
    },
    toFin() {
      let url = `https://rzx.smeqh.cn/web/#/financialproducts`;
      window.open(url, "_blank");
    },
    // 通知文件 工作动态 更多 跳转
    // toAppealMore() {
    //   if (this.appealShow == 0) {
    //     let url = `https://sq.smeqh.cn/#/portal/duty/list`;
    //     window.open(url, "_blank");
    //   }

    //   if (this.appealShow == 1) {
    //     let url = `https://sq.smeqh.cn/#/portal/notify`;
    //     window.open(url, "_blank");
    //   }
    // },
    // 通知文件 工作动态 跳转
    // toAppeal(id, type) {
    //   // 工作动态
    //   if (type == 1) {
    //     let url = `https://sq.smeqh.cn/#/portal/duty/detail?id=${id}`;
    //     window.open(url, "_blank");
    //   }

    //   // 通知文件
    //   if (type == 2) {
    //     let url = `https://sq.smeqh.cn/#/portal/notifyDetail?id=${id}`;
    //     window.open(url, "_blank");
    //   }
    // },
    // // 诉求列表
    // getAppealList() {
    //   axios({
    //     url: "https://sq.smeqh.cn/appeal/portal/info/appealList",
    //     data: {
    //       page: 1,
    //       size: 10000,
    //       order: "create_time desc",
    //       param: {
    //         enterpriseCode: "Ae93jd34j89",
    //       },
    //     },
    //     method: "POST",
    //   }).then((res) => {
    //     this.tableData = res.data.list;
    //   });
    // },
    // 通知文件
    // getNotifyFile() {
    //   axios({
    //     url: "https://sq.smeqh.cn/appeal/portal/notifyFile/list",
    //     data: {
    //       page: 1,
    //       size: 9,
    //       order: "create_time desc",
    //     },
    //     method: "POST",
    //   }).then((res) => {
    //     this.appealList.inform = res.data.list;
    //   });
    // },
    // 工作动态
    getDutyList() {
      axios({
        url: "https://sq.smeqh.cn/appeal/portal/duty/list",
        data: {
          page: 1,
          size: 8,
          order: "create_time desc",
        },
        method: "POST",
      }).then((res) => {
        this.appealList.work = res.data.list;
      });
    },
    // 协同服务 地图
    getGovernmentCity(type = 1, index = 0) {
      let data = {
        type,
      };
      this.$get("/links_list", data).then((res) => {
        if (res.status == 1) {
          if (index == 0) {
            this.governmentCity.municipal = res.result ? res.result : [];
          }

          if (index == 1) {
            this.governmentCity.areaGovernment = res.result ? res.result : [];
          }

          if (index == 2) {
            this.governmentCity.link = res.result ? res.result : [];
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 协同服务 跳转
    toTypeServe() {
      if (this.synergyShow == 0) {
        this.$router.push("/servicealliance");
      }

      if (this.synergyShow == 1) {
        this.$router.push("/expertbase");
      }
    },
    // 协同服务 新
    getServiceList(type) {
      // type  1服务   2专家库
      // limit: 8
      let data = {
        type,
        page: 1,
        limit: 10000,
      };

      this.$post("service_list", data).then((res) => {
        if (res.status == 1) {
          if (type == 1) {
            this.tableAlliance = res.result ? res.result : [];
          }

          if (type == 2) {
            this.tableExpert = res.result ? res.result : [];
          }
        }
      });
    },

    initmap() {
      AMapLoader.load({
        // key: "0a2070e076274c079bf17b177fbb4839", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        center: [117.258134, 38.98059],
        zoom: 13,
        plugins: [], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          console.log(AMap, 'AMap')


        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  // 跳转关闭定时器
  beforeRouteLeave(to, from, next) {
    clearInterval(this.timer);
    clearInterval(this.atimer);
    clearInterval(this.etimer);
    clearInterval(this.ftimer);

    next();
  },
  async mounted() {
    this.getcity();

    // this.initMap()

    // var map = new AMap.Map("container", {
    //   zoom: 6.3,
    //   center: [97.008522, 33.004049],
    //   pitch: 0,
    //   viewMode: "3D",
    //   // 设置地图背景图
    //   mapStyle: "amap://styles/ed9fff578638aa794e91839ea9c3b7d5",
    // });

    // just some colors
    let colors = [
      "#3366cc",
      "#dc3912",
      "#ff9900",
      "#109618",
      "#990099",
      "#0099c6",
      "#dd4477",
      "#66aa00",
    ];

    // AMapUI.loadUI(["geo/DistrictExplorer"], function (DistrictExplorer) {
    //   // 启动页面
    //   initPage(DistrictExplorer);
    // });
    var currentAreaNode = null;
    var districtExplorer;
    // function initPage(DistrictExplorer) {
    //   // 创建一个实例
    //   districtExplorer = new DistrictExplorer({
    //     eventSupport: true, //打开事件支持
    //     map: map, // 关联的地图实例
    //   });
    //   var adcode = 630000; // 青海区划编码

    //   districtExplorer.loadAreaNode(adcode, function (error, areaNode) {
    //     if (error) {
    //       console.error(error);
    //       return;
    //     }
    //     areaNode.getAdcode();
    //     // 绘制载入的区划节点
    //     renderAreaNode(districtExplorer, areaNode);
    //   });
    // }

    // function switch2AreaNode(adcode, callback) {
    //   if (currentAreaNode && "" + currentAreaNode.getAdcode() === "" + adcode) {
    //     return;
    //   }

    //   loadAreaNode(adcode, (error, areaNode) => {
    //     if (error) {
    //       if (callback) {
    //         callback(error);
    //       }

    //       return;
    //     }

    //     currentAreaNode = areaNode;
    //     //设置当前使用的定位用节点
    //     districtExplorer.setAreaNodesForLocating([currentAreaNode]);

    //     renderAreaPolygons(areaNode);

    //     if (callback) {
    //       callback(null, areaNode);
    //     }
    //   });
    // }

    //绘制某个区域的边界
    function renderAreaPolygons(areaNode) {
      //更新地图视野
      map.setBounds(areaNode.getBounds(), null, null, true);

      //清除已有的绘制内容
      districtExplorer.clearFeaturePolygons();

      //绘制子区域
      districtExplorer.renderSubFeatures(areaNode, function (feature, i) {
        var fillColor = colors[i % colors.length];
        var strokeColor = colors[colors.length - 1 - (i % colors.length)];

        return {
          cursor: "default",
          bubble: true,
          strokeColor: strokeColor, //线颜色
          strokeOpacity: 1, //线透明度
          strokeWeight: 1, //线宽
          fillColor: fillColor, //填充色
          fillOpacity: 0.35, //填充透明度
        };
      });

      //绘制父区域
      districtExplorer.renderParentFeature(areaNode, {
        cursor: "default",
        bubble: true,
        strokeColor: "black", //线颜色
        strokeOpacity: 1, //线透明度
        strokeWeight: 1, //线宽
        fillColor: areaNode.getSubFeatures().length ? null : colors[0], //填充色
        fillOpacity: 0.35, //填充透明度
      });
    }

    //加载区域
    function loadAreaNode(adcode, callback) {
      districtExplorer.loadAreaNode(adcode, function (error, areaNode) {
        if (error) {
          if (callback) {
            callback(error);
          }

          console.error(error);

          return;
        }

        // renderAreaPanel(areaNode);

        if (callback) {
          callback(null, areaNode);
        }
      });
    }

    function renderAreaNode(districtExplorer, areaNode) {
      // 清除已有的绘制内容
      districtExplorer.clearFeaturePolygons();

      // 绘制子级区划
      districtExplorer.renderSubFeatures(areaNode, function (feature, i) {
        var fillColor = colors[i % colors.length];
        var strokeColor = colors[colors.length - 1 - (i % colors.length)];

        return {
          cursor: "default",
          bubble: true,
          strokeColor: strokeColor, // 线颜色
          strokeOpacity: 1, // 线透明度
          strokeWeight: 1, // 线宽
          fillColor: fillColor, // 填充色
          fillOpacity: 0.35, // 填充透明度
        };
      });

      // 绘制父级区划，仅用黑色描边
      districtExplorer.renderParentFeature(areaNode, {
        cursor: "default",
        bubble: true,
        strokeColor: "#01185b", // 线颜色
        fillColor: null,
        strokeWeight: 3, // 线宽
      });

      districtExplorer.on(
        "featureMouseout featureMouseover",
        function (e, feature) {
          var isHover = e.type === "featureMouseover";

          if (!isHover) {
            // tipMarker.setMap(null);
            return;
          }

          // tipMarker.setMap(map);

          // tipMarker.setPosition(e.originalEvent.lnglat);

          // tipMarker.setLabel({
          //     offset: new AMap.Pixel(20, 20),
          //     content: feature.properties.name
          // });

          // var props = feature.properties;

          // console.log(props, 'props')
          // this.adcode = props.adcode;
          // console.log(this.adcode, ' this.adcode ')
          // switch2AreaNode(props.adcode);
        }
      );

      districtExplorer.on("featureClick", function (e, feature) {
        var props = feature.properties;

        this.adcode = props.adcode;

        switch2AreaNode(props.adcode);
      });
      districtExplorer.on("outsideClick", function (e, feature) {
        districtExplorer.locatePosition(
          e.originalEvent.lnglat,
          function (error, routeFeatures) {
            if (routeFeatures && routeFeatures.length > 1) {
              //切换到省级区域
              switch2AreaNode(630000);
            } else {
              //切换到全国
              switch2AreaNode(630000);
            }
          },
          {
            levelLimit: 2,
          }
        );
      });

      // 更新地图视野以适合区划面
      map.setFitView(districtExplorer.getAllFeaturePolygons());
    }

    window.addEventListener("scroll", this.srcollShow);

    // const myCharts2 = this.$echarts.init(this.$refs.Charts2);

    // let bgColor = "#fff";
    // let title = "总量";
    // let titles = "户";
    // let color = [
    //   "#ff8e43",
    //   "#3862d8",
    //   "#6a89e2",
    //   "#219cf9",
    //   "#6efbbf",
    //   "#ff8e43",
    //   "#c065e7",
    //   "#f56b6d",
    // ];
    // // let echartData = [
    // //   {
    // //     name: "西宁市",
    // //     value: "27047",
    // //   },
    // //   {
    // //     name: "海东市",
    // //     value: "13078",
    // //   },
    // //   {
    // //     name: "海西蒙古族藏族自治州",
    // //     value: "4744",
    // //   },
    // //   {
    // //     name: "海北藏族自治州",
    // //     value: "2343",
    // //   },
    // //   {
    // //     name: "海南藏族自治州",
    // //     value: "2814",
    // //   },
    // //   {
    // //     name: "玉树藏族自治州",
    // //     value: "1244",
    // //   },
    // //   {
    // //     name: "黄南藏族自治州",
    // //     value: "1574",
    // //   },
    // //   {
    // //     name: "果洛藏族自治州",
    // //     value: "687",
    // //   },
    // // ];

    // let echartData = [
    //   {
    //     name: "省级中小企业示范平台",
    //     value: "42",
    //   },
    //   {
    //     name: "青海省中小企业公共服务联盟成员单位",
    //     value: "42",
    //   },
    //   {
    //     name: "中小企业公共服务平台网络“窗口”平台",
    //     value: "21",
    //   },
    //   {
    //     name: "省级小型微型企业创业创新示范基",
    //     value: "18",
    //   },
    //   {
    //     name: "国家级中小企业示范平台",
    //     value: "15",
    //   },
    //   {
    //     name: "国家级小型微型企业创业创新示范基",
    //     value: "5",
    //   },
    // ];

    // let formatNumber = function (num) {
    //   let reg = /(?=(\B)(\d{3})+$)/g;
    //   return num.toString().replace(reg, ",");
    // };
    // let total = echartData.reduce((a, b) => {
    //   return a + b.value * 1;
    // }, 0);

    // let options = {
    //   backgroundColor: bgColor,
    //   color: color,
    //   tooltip: {
    //     trigger: "item",
    //   },
    //   title: [
    //     {
    //       text: "{name|" + title + "}\n{val|" + formatNumber(total) + "}",
    //       top: "center",
    //       left: "center",
    //       textStyle: {
    //         rich: {
    //           name: {
    //             fontSize: 16,
    //             fontWeight: "normal",
    //             color: "#000",
    //             padding: [10, 0],
    //           },
    //           val: {
    //             fontSize: 28,
    //             fontWeight: "bolder",
    //             color: "#000",
    //           },
    //         },
    //       },
    //     },
    //     {
    //       text: "单位：个",
    //       top: 20,
    //       left: 20,
    //       textStyle: {
    //         fontSize: 14,
    //         color: "#666666",
    //         fontWeight: 400,
    //       },
    //       show: false,
    //     },
    //   ],
    //   series: [
    //     {
    //       type: "pie",
    //       roseType: "radius",
    //       radius: ["25%", "60%"],
    //       center: ["50%", "50%"],
    //       data: echartData,
    //       hoverAnimation: false,
    //       itemStyle: {
    //         normal: {
    //           borderColor: bgColor,
    //           borderWidth: 2,
    //         },
    //       },
    //       labelLine: {
    //         normal: {
    //           length: 20,
    //           length2: 80,
    //           lineStyle: {
    //             // color: '#e6e6e6'
    //           },
    //         },
    //       },
    //       label: {
    //         normal: {
    //           formatter: (params) => {
    //             return (
    //               "{icon|●}{name|" +
    //               params.name +
    //               "}\n{value|" +
    //               formatNumber(params.value) +
    //               "户" +
    //               "}"
    //             );
    //           },
    //           // padding: [0 , -100, 25, -100],
    //           rich: {
    //             icon: {
    //               fontSize: 16,
    //               color: "inherit",
    //             },
    //             name: {
    //               fontSize: 18,
    //               padding: [0, 0, 0, 10],
    //               color: "#000",
    //             },
    //             value: {
    //               fontSize: 14,
    //               fontWeight: "bolder",
    //               padding: [10, 0, 0, 20],
    //               color: "inherit",
    //               // color: '#333333'
    //             },
    //           },
    //         },
    //       },
    //     },
    //   ],
    // };

    // myCharts2.setOption(options);

    this.getBannerList();
    this.getBannersmallList();
    this.getBanner_img(); //中部图片
    this.getNewsPort();
    this.getNoticePort();

    this.getPolicyClass();

    this.getCore();
    this.getFinlist();

    this.getProList();

    // this.getGovernmentCity()
    this.getServiceList(1);
    // this.allianceMove();

    // this.getAppealList();
    // this.getDutyList();
    // this.getNotifyFile();

    // this.tableMove();
    // this.financialMove();
    // this.open();
  },
  destroyed() {
    clearInterval(this.timer);
  },
};
</script>

<style scoped>
@import url("./index.css");
#myCharts {
  width: 710px;
  height: 260px;
}
#myCharts2 {
  width: 710px;
  height: 550px;
}
#myCharts3 {
  width: 710px;
  height: 260px;
}
.imgbox {
  height: 112px;
}

.specialbox:hover {
  border: 1px solid #fb1010;
}

.topimg:hover {
  transform: scale(1.1);
  transition: all 0.5s;
}
.topimg {
  width: 100%;
  height: 100%;
}

.titlehave:hover {
  color: #fb1010 !important;
}

.servehove:hover {
  color: #ff7129;
}

.carousel {
  width: 700px;
  height: 490px !important;
}

.totop {
  width: 70px;
  height: 70px;
  line-height: 70px;
  border-radius: 50px;
  background-color: #ff7129;
  position: fixed;
  bottom: 75px;
  right: 40px;
  text-align: center;
  color: #ffffff;
}

#container {
  width: 1480px;
  height: 1000px;
}

#test-map {
  width: 1480px;
  height: 1000px;
}
</style>
