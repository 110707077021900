<template>
  <div class="rig_navigation" ref="rights">
    <div class="rig_title" @click="showorhidenNav">
      <div>导航栏</div>

      <div class="rig_icon">
        <i class="el-icon-arrow-left n_arrow" v-if="flag"></i>
        <i class="el-icon-arrow-right n_arrow" v-else></i>
      </div>
    </div>

    <div>
      <div
        class="rig_a"
        @click="scrollMove(1)"
        :class="{ actives: nindex == 1 }"
      >
        首页
      </div>
      <div
        class="rig_a"
        @click="scrollMove(2)"
        :class="{ actives: nindex == 2 }"
      >
        政策法规
      </div>
      <div
        class="rig_a"
        @click="scrollMove(3)"
        :class="{ actives: nindex == 3 }"
      >
        工业小微企业融资服务
      </div>
      <div
        class="rig_a"
        @click="scrollMove(4)"
        :class="{ actives: nindex == 4 }"
      >
        诉求意见及保障支付
      </div>
      <div
        class="rig_a"
        @click="scrollMove(5)"
        :class="{ actives: nindex == 5 }"
      >
        协同服务
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      flag: true,
      nindex: 1,
    };
  },
  methods: {
    showorhidenNav() {
      this.flag = !this.flag;
      console.log("this.flag", this.flag);

      if (this.flag) {
        this.$refs.rights.style.right = "0px";
      } else {
        this.$refs.rights.style.right = "-128px";
      }
    },
    scrollMove(index) {
      this.nindex = index;
      this.$emit("scrollMove", index);
    },
  },
};
</script>

<style scoped>
@import url("./rignaviagtion.css");
</style>